import { AbstractAuthService } from './AbstractAuthService';

export class PermissionService extends AbstractAuthService {
    getPermissions() : Promise<Array<string>> {
        const segments = ['api', 'permissions'];
        return this.get({ segments }).then((response) => {
            if (!response.ok) {
                throw response.createError();
            }

            return response.json();
        });
    }
}
