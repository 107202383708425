import { AppState, useAuth0 } from '@auth0/auth0-react';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingIndicator from '../LoadingIndicator';

function Callback() {
    // const { auth } = useAuthentication();
    const navigate = useNavigate();
    const shouldRedirect = useRef(true);

    const { handleRedirectCallback } = useAuth0();

    type RedirectLoginResult<T> = Awaited<ReturnType<typeof handleRedirectCallback>>;

    useEffect(() => {
        if (!shouldRedirect.current) {
            return;
        }


        shouldRedirect.current = false;
        // if (!/access_token|id_token|error/.test(location.hash)) {
        //     throw new Error('Invalid callback URL');
        // }

        // auth?.handleAuthenticationAsync();
        handleRedirectCallback()
            .then((result: RedirectLoginResult<AppState>) => navigate(result.appState?.returnTo ?? '/'))
            .catch((error: Error) => {
                console.log(error);

                navigate('/');
            });
    }, []);

    return <LoadingIndicator />;
}

export { Callback };
