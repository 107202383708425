import { CSSProperties, PureComponent } from 'react';
import { ImgIconBase, ImgIconDetailedHTMLProps } from './ImgIconBase';

export class ArrowRight extends PureComponent<ImgIconDetailedHTMLProps> {
    render() {
        const style = {
            ...this.props.style,
            transformBox: 'fill-box',
            transformOrigin: 'center',
            transform: 'rotate(270deg)',
        } as CSSProperties;

        return (
            <ImgIconBase src='/images/icons/arrow-down-1px.svg' style={style} {...this.props} />
        );
    }
}