import { Observable } from '@babylonjs/core/Misc/observable';
import { ID } from '../utils';
import { SharedAccessSignatureBlob } from './SharedAccessSignatureBlob';
import { Buffer } from 'buffer';

export class SharedAccessSignatureChunk {
    public readonly sharedAccessSignatureBlob: SharedAccessSignatureBlob;
    public readonly index: number;
    public readonly chunk: Blob;

    #_chunkId: string;
    #_isCompleted: boolean = false;

    constructor(sharedAccessSignatureBlob: SharedAccessSignatureBlob, index: number, chunk: Blob) {
        this.sharedAccessSignatureBlob = sharedAccessSignatureBlob;
        this.index = index;
        this.chunk = chunk;
        this.#_chunkId = this.getNewChunkId();
    }

    get file() : Blob {
        return this.chunk;
    }

    get chunkId() : string {
        return this.#_chunkId;
    }

    set chunkId(chunkId: string) {
        if (this.isCompleted) {
            throw new Error('You cannot change the chunkid when the upload is completed');
        }

        this.#_chunkId = chunkId;
    }

    get contentType() : string {
        return this.sharedAccessSignatureBlob.contentType;
    }

    get isCompleted() : boolean {
        return this.#_isCompleted;
    }

    get size() : number {
        return this.chunk.size;
    }

    getNewChunkId() : string {
        const id = ID();
        return Buffer.from(id, 'ascii').toString('base64');
    }

    regenerateChunkId = () => {
        this.chunkId = this.getNewChunkId();
    }

    getSasUrl = () : URL => {
        const sasUrl = this.sharedAccessSignatureBlob.getSasUrl();
        const url = new URL(sasUrl.toString());
        url.searchParams.append('comp', 'block');
        url.searchParams.append('blockid', this.chunkId);

        return url;
    }

    progress = (props: ISharedAccessSignatureChunkProgress) => {
        this.OnProgress.notifyObservers({ ...props, chunk: this });
    };

    complete = () => {
        this.#_isCompleted = true;
        this.OnCompleted.notifyObservers({ chunk: this });
        this.OnProgress.clear();
        this.OnCompleted.clear();
        this.OnFailure.clear();
        this.OnRetrying.clear();
    }

    fail = () => {
        this.OnFailure.notifyObservers({ chunk: this });
    }

    retry = () => {
        this.regenerateChunkId();
        this.OnRetrying.notifyObservers({ chunk: this });
    }

    public readonly OnCompleted: Observable<ISharedAccessSignatureChunkOnCompleted> = new Observable<ISharedAccessSignatureChunkOnCompleted>();
    public readonly OnFailure: Observable<ISharedAccessSignatureChunkOnFailure> = new Observable<ISharedAccessSignatureChunkOnFailure>();
    public readonly OnProgress: Observable<ISharedAccessSignatureChunkOnProgress> = new Observable<ISharedAccessSignatureChunkOnProgress>();
    public readonly OnRetrying: Observable<ISharedAccessSignatureChunkOnRetrying> = new Observable<ISharedAccessSignatureChunkOnRetrying>();
}

export interface ISharedAccessSignatureChunkOnCompleted {
    chunk: SharedAccessSignatureChunk;
}

export interface ISharedAccessSignatureChunkOnFailure {
    chunk: SharedAccessSignatureChunk;
}

export interface ISharedAccessSignatureChunkProgress {
    loaded: number;
    total: number;
}

export interface ISharedAccessSignatureChunkOnProgress {
    loaded: number;
    total: number;
    chunk?: SharedAccessSignatureChunk;
}

export interface ISharedAccessSignatureChunkOnRetrying {
    chunk: SharedAccessSignatureChunk;
}