import { Component, MouseEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { File as CoreFile } from '../../core/File';
import { Icon as IconButton } from '../Buttons/Icon';
import FileDownloadLink from '../FileDownloadLink';
import * as Icons from '../Icons';
import ProtectedElement from '../ProtectedElement';


type FileLineProps = WithTranslation & {
    file: CoreFile;
    onDelete?: (file: CoreFile) => void;
    onClick?: (file: CoreFile) => boolean;
    permissionsToDelete?: Array<string>;
}

class FileLine extends Component<FileLineProps> {

    onClick = (event: MouseEvent<HTMLAnchorElement>) => {
        const { file, onClick } = this.props;
        const { isExternal = false } = file;

        if (isExternal || !onClick || onClick(file)) {
            return true;
        }

        if (event.cancelable) {
            event.stopPropagation();
            event.preventDefault();
        }

        return false;
    }

    onDelete = () => {
        const { t, onDelete, file } = this.props;
        const label = t('components.FileUpload.Remove file {file}?', { file: file.fileName });

        if (!onDelete || !confirm(label)) {
            return;
        }

        return onDelete(this.props.file);
    }

    render() {
        const { t, onDelete, file, permissionsToDelete = [] } = this.props;

        const label = t('components.FileUpload.Remove file {file}?', { file: file.fileName });

        const del = onDelete
            ? <IconButton onClick={this.onDelete} title={label} ><Icons.Trash title={label} /></IconButton>
            : null;

        const {name, fileName, isExternal = false } = file;

        return (
            <tr className='mb-2 mt-2 vincotte-shadow' style={{ lineHeight: '42px' }} >
                <td scope='row' className='table-light rounded-left' style={{maxWidth:'155px', overflowWrap:'anywhere'}}>
                    {name} (<FileDownloadLink file={file} onClick={this.onClick} >{fileName}</FileDownloadLink>)
                </td>

                <ProtectedElement somePermission={permissionsToDelete}>
                    <td className='table-light rounded-right' style={{ width: '42px' }} >{del}</td>
                </ProtectedElement>
            </tr>
        );
    }
}

const FileLineWithTranslation = withTranslation()(FileLine);
export { FileLineWithTranslation as FileLine };
