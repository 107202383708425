import { Component } from 'react';
import Error, { ErrorMessageProps as ErrorProps } from '../ErrorMessage';

type ErrorMessageProps = ErrorProps;

export class ErrorMessage extends Component<ErrorMessageProps> {
    render() {
        return (
            <Error {...this.props} />
        );
    }
}