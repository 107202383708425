import { ApiResponse } from '../services/ApiResponse';
import { SharedAccessSignatureBlob } from './SharedAccessSignatureBlob';
import { ISharedAccessSignatureChunkOnProgress, SharedAccessSignatureChunk } from './SharedAccessSignatureChunk';
import { XMLHttpRequestReadyState } from '../types/XMLHttpRequestReadyState';

export class SharedAccessSignatureUploader {
    #_failureAttemptsInRow: number = 0;

    constructor() {
        this.failureAttemptsInRow = 0;
    }

    get failureAttemptsInRow() {
        return this.#_failureAttemptsInRow;
    }

    private set failureAttemptsInRow(failureAttemptsInRow) {
        this.#_failureAttemptsInRow = failureAttemptsInRow;
    }

    upload = (blob: SharedAccessSignatureBlob, abortSignal?: AbortSignal) => {
        return this.chainUpload(blob, abortSignal).then(() => {
            if (blob.isChunkUpload) {
                const xmlBlockList = blob.getBlockList();

                const putRangeBlockListUrl = blob.getBlockListUrl();

                const init = {
                    method: 'PUT',
                    headers: {
                        Accept: '*/*',
                        'Content-Type': 'text/plain;charset=UTF-8',
                        'x-ms-blob-content-type': blob.contentType,
                    },
                    signal: abortSignal,
                    body: xmlBlockList,
                };

                // eslint-disable-next-line no-unused-vars
                return fetch(putRangeBlockListUrl.toString(), init).then((response) => {
                    return Promise.resolve(true);
                });
            }

            return Promise.resolve(true);
        });
    }

    chainUpload = (blob: SharedAccessSignatureBlob, abortSignal?: AbortSignal) : Promise<ApiResponse | boolean> => {
        const chunk = blob.next();

        if (chunk === false) {
            return Promise.resolve(true);
        }

        const retry = () : Promise<ApiResponse | boolean>  => {
            if (this.failureAttemptsInRow >= 5) {
                const error = new Error('Upload error: it was not possible to continue the upload');
                error.name = 'Error';

                throw error;
            }

            const timeMs = Math.pow(2, this.failureAttemptsInRow) * 1000;
            this.failureAttemptsInRow++;

            if (chunk instanceof SharedAccessSignatureChunk) {
                chunk.fail();
            }

            return new Promise<ApiResponse | boolean>((resolve) => setTimeout(
                () => resolve(this.chainUpload(blob, abortSignal)), timeMs)
            );
        };

        if (!(chunk instanceof SharedAccessSignatureChunk) && !(chunk instanceof SharedAccessSignatureBlob)) {
            return Promise.resolve(false);
        }

        return this.azureUpload(chunk, abortSignal).then((response: ApiResponse) => {
            if (abortSignal && abortSignal.aborted) {
                const error = new Error('Upload aborted');
                error.name = 'AbortError';
                return Promise.reject(error);
            }

            if (!response.ok) {
                return retry();
            }

            chunk.complete();

            this.failureAttemptsInRow = 0;

            return this.chainUpload(blob, abortSignal);
        });
    }

    azureUpload = (blob: SharedAccessSignatureBlob | SharedAccessSignatureChunk, abortSignal?: AbortSignal) : Promise<ApiResponse> => {
        const uploadUri = blob.getSasUrl();
        const { contentType } = blob;

        const xhr = new XMLHttpRequest();
        xhr.open('PUT', uploadUri, true);

        xhr.setRequestHeader('Content-Type', `${contentType}`);
        xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob');
        xhr.setRequestHeader('x-ms-version', '2019-12-12');

        const abortListener =  () => xhr.abort();

        if (abortSignal && abortSignal instanceof AbortSignal) {
            abortSignal.addEventListener('abort', abortListener);
        }

        const progress = (e: ProgressEvent) => {
            const progress: ISharedAccessSignatureChunkOnProgress = {
                loaded: e.loaded,
                total: e.total
            };

            blob.progress(progress);
        };

        const complete = (e: ProgressEvent) => {
            if (xhr.readyState === 4) {
                blob.complete();
            }
        };

        xhr.upload.addEventListener('loadstart', progress, false);
        xhr.upload.addEventListener('progress', progress, false);
        xhr.upload.addEventListener('load', complete, false);
        xhr.upload.addEventListener('load', () => {
            if (abortSignal) {
                abortSignal.removeEventListener('abort', abortListener);
            }
        }, false);

        // xhr.addEventListener('loadstart', progress, false);
        // xhr.addEventListener('progress', progress, false);
        // xhr.addEventListener('load', progress, false);
        // xhr.addEventListener('load', () => abortSignal.removeEventListener('abort', abortListener), false);

        const promise = new Promise<ApiResponse>((resolve, reject) => {
            xhr.addEventListener('abort', () => {
                const error = new Error('Upload aborted');
                error.name = 'AbortError';
                reject(error);
            } , false);

            xhr.addEventListener('error', () => {
                resolve(ApiResponse.createErrorInstance('Upload error'));
            } , false);

            xhr.addEventListener('load', () => {
                if (xhr.readyState === XMLHttpRequestReadyState.DONE) {
                    ApiResponse.createInstance(xhr).then(resolve).catch(reject);
                }
            }, false);
        });

        xhr.send(blob.file);

        return promise;
    }
}