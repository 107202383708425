import { ComponentType, createElement, useEffect, useRef, useState } from 'react';
import { Subtract } from 'utility-types';
import { UseAuthentication } from '../hooks/useAuthentication';
import { useAuth0 } from '@auth0/auth0-react';
import { InternetStatusClient, OnInternetStatusChange } from '../worker/InternetStatus/InternetStatusClient';

export interface WithConnectionProps {
    isOnline: boolean;
}

export function withConnection() {
    return function <TProps extends WithConnectionProps = WithConnectionProps>(WrappedComponent: ComponentType<TProps>)  {
        function handler(props: Subtract<TProps, WithConnectionProps> & UseAuthentication) {
            const { auth, ...rest } = props;

            const [ isOnline, setIsOnline ] = useState(true);

            const { getAccessTokenSilently } = useAuth0();

            useEffect(() => {
                const internetStatusClient = InternetStatusClient.getInstance();
                setIsOnline(internetStatusClient.IsOnline);

                getAccessTokenSilently().then((token) => internetStatusClient.start(token));

                const observer = (message: OnInternetStatusChange) => setIsOnline(message.isOnline);

                InternetStatusClient.OnStatusChange.add(observer);

                return () => {
                    InternetStatusClient.OnStatusChange.removeCallback(observer);
                    internetStatusClient.terminate();
                };
            }, []);

            const renderedProps = {
                ...rest,
                isOnline
            } as TProps;

            return createElement(WrappedComponent, renderedProps);
        }

        const name = WrappedComponent.displayName || WrappedComponent.name;
        handler.displayName = `withConnection(${name})`;
        handler.WrappedComponent = WrappedComponent;

        return handler;
    };
}