import { CSSProperties, HTMLAttributes, memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoadingIndicatorHooks } from './LoadingIndicator.hooks';
import './LoadingIndicator.css';
import { PreventWindowUnload } from '../../utils/PreventWindowUnload';

type LoadingIndicatorProps = HTMLAttributes<HTMLDivElement> & {
        preventUnload?: boolean;
        unloadMessage?: string;
    };

const divStyle: CSSProperties = {
    width: '100%',
    // height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const LoadingIndicator = memo((props: Readonly<LoadingIndicatorProps>) => {
    useLoadingIndicatorHooks(this);

    const { t } = useTranslation();

    const {
        preventUnload = false,
        unloadMessage = t('components.LoadingIndicator.There is data being saved.'),
        ...rest
    } = props;

    const componentId = useMemo(() => PreventWindowUnload.generateId(this), []);

    useEffect(() => {
        if (preventUnload) {
            PreventWindowUnload.prevent(componentId, this);
        } else  {
            PreventWindowUnload.release(componentId);
        }

        return () => PreventWindowUnload.release(componentId);
    }, [ preventUnload ]);

    const { style, className, ...restToRender } = rest;

    const dStyle = { ...divStyle, ...style };
    const cName = `${className} loading-indicator`;

    // const confirmationMessage = (unloadMessage ? (unloadMessage + ' ' ) : '') + t('components.LoadingIndicator.Are you sure you want to leave?');

    return (
        <div style={dStyle} className={cName} {...restToRender} >
            {/* <Prompt when={preventUnload} message={confirmationMessage} /> */}

            <div className="loader-cubes">
                <div className="cube">
                    <div className='face face-front'></div>
                    <div className='face face-back'></div>
                    <div className='face face-left'></div>
                    <div className='face face-right'></div>
                    <div className='face face-top'></div>
                    <div className='face face-bottom'> </div>
                </div>
                <div className="cube" style={{left:'5em',position:'absolute',top:'25%',WebkitAnimationDelay:' 2s',
                    animationDelay: '-1000ms'}}>
                    <div className='face face-front'></div>
                    <div className='face face-back'></div>
                    <div className='face face-left'></div>
                    <div className='face face-right'></div>
                    <div className='face face-top'></div>
                    <div className='face face-bottom'> </div>
                </div>

                <div className="cube"  style={{right:'5em',position:'absolute',top:'25%',WebkitAnimationDelay:' 2s',
                    animationDelay: '-2000ms'}}>
                    <div className='face face-front'></div>
                    <div className='face face-back'></div>
                    <div className='face face-left'></div>
                    <div className='face face-right'></div>
                    <div className='face face-top'></div>
                    <div className='face face-bottom'> </div>
                </div>
            </div>
        </div>
    );
});

LoadingIndicator.displayName = 'LoadingIndicator';

export { LoadingIndicator };
