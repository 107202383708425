import { useMemo, useState } from 'react';
import { LanguageSelector } from './LanguageSelector';

interface LanguagesSelectorProps {
    isMenuMinimized?: boolean;
}

function LanguagesSelector({ isMenuMinimized = false }: LanguagesSelectorProps) {
    const [ languages, setLanguages ] = useState<boolean>(false);

    const toggleLanguages = useMemo(() => () => setLanguages((languages) => !languages), []);

    return (
        <div className="optionItem position-relative" onMouseEnter={toggleLanguages} onMouseLeave={toggleLanguages}>
            <p className='mb-0' >Language ( English )
                <svg style={{marginLeft:'1em'}} width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L7 7L1 13" stroke="black" strokeWidth="2" strokeLinecap="round"/>
                </svg>
            </p>
            {
                languages ?
                    <div className='popUp-Menu-wrapper'>
                        <div className='popUp-Menu shadow' >
                            <LanguageSelector isMenuMinimized={isMenuMinimized} />
                        </div>
                    </div>
                    : null

            }
        </div>
    );
}

export { LanguagesSelector };