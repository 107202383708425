import { Component, ReactNode } from 'react';

type ListTitleProps = {
    children?: ReactNode;
}

export class ListTitle extends Component<ListTitleProps> {
    render() {
        return (<h2>{this.props.children}</h2>);
    }
}