import { HTMLAttributes } from 'react';
import './AutoRow.css';

function AutoRow({ className: cn = '', ...rest }: HTMLAttributes<HTMLDivElement>) {
    const className = `auto-row ${cn}`;

    return <div className={className} {...rest} />;
}

export { AutoRow };
