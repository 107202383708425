import { StatusCodes } from 'http-status-codes';
import { ApiException, FileToClientDto, IClientFilesClient } from '../api/vincotte.via.api';
import { File } from '../components/FileUpload';
import { StringHelper } from '../utils';
import { SharedAccessSignature } from '../azure/SharedAccessSignature';
import { ApiResponse } from './ApiResponse';

export class ClientFilesService {

    #_client : IClientFilesClient;

    constructor(client: IClientFilesClient) {
        this.#_client = client;
    }

    addFile = async (
        clientMoniker: string,
        file: File,
        abortSignal?: AbortSignal,
        onProgress = (args: any) => {},
        onFailure = (args: any) => {},
    ) => {
        const fileName = StringHelper.removeSpecialCharacters(file.fileName);

        return this.#_client.createUploadUrlForClient(clientMoniker, fileName, undefined).then((createdUploadUrl) => {
            const { uploadUrl, fileExists, ...rest } = createdUploadUrl;

            if (!createdUploadUrl.uploadUrl) {
                throw new Error('Invalid Upload URL');
            }

            return this.uploadFile(createdUploadUrl.uploadUrl, file.file, abortSignal, onProgress, onFailure).then(() => {
                return { fileName, ...rest };
            });
        }).catch((error: ApiException) => {
            if (error.status === StatusCodes.CONFLICT) {
                const { response } = error;

                const res = (response !== undefined && response !== null && response.constructor == String)
                    ? { message: response }
                    : response as any as Record<string, any>;

                return { fileName, ...res };
            }

            throw error;
        }).then((uploadedFile) => {
            const clientFile = new FileToClientDto({ fileName: uploadedFile.fileName, name: file.name });

            return this.#_client.addFileToClient(clientMoniker, clientFile);
        });
    }

    uploadFile = (
        uploadUrl: string,
        file: globalThis.File,
        abortSignal?: AbortSignal,
        onProgress = (args: any) => {},
        onFailure = (args: any) => {},
    ) => {
        const sharedAccessSignature = new SharedAccessSignature(uploadUrl, file);
        sharedAccessSignature.OnProgress.add(onProgress);
        sharedAccessSignature.OnFailure.add(onFailure);

        return sharedAccessSignature.upload(abortSignal).then((response: any) => {
            if (response !== true) {
                throw new Error(response);
            }

            const responseJsonString =  JSON.stringify({ name: StringHelper.getFileName(uploadUrl) });
            return new ApiResponse(true, responseJsonString, 201);
        });
    }
}