import { createContext, ReactNode } from 'react';
import { BackgroundColor } from './BackgroundColor';

interface PageBackgroundColorContextProps {
    color: string;
    setBackgroundColor: (backgroundColor: string) => void;
}

const PageBackgroundColorContext = createContext<PageBackgroundColorContextProps>({
    color: BackgroundColor.DefaultPage,
    setBackgroundColor: (backgroundColor: string) => {}
});

PageBackgroundColorContext.displayName = 'PageBackgroundColorContext';

interface PageBackgroundColorProviderProps extends PageBackgroundColorContextProps {
    children: ReactNode;
}

function PageBackgroundColorProvider({children, ...state} : PageBackgroundColorProviderProps) {
    return (<PageBackgroundColorContext.Provider value={state}>{children}</PageBackgroundColorContext.Provider>);
}

export { PageBackgroundColorContext, PageBackgroundColorProvider };