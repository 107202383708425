import { PureComponent } from 'react';
import { ImgIconBase, ImgIconDetailedHTMLProps } from './ImgIconBase';

type Close3Props = ImgIconDetailedHTMLProps;

export class Close3 extends PureComponent<Close3Props> {
    render() {
        return (
            <ImgIconBase src='/images/icons/close-3.svg' {...this.props} />
        );
    }
}