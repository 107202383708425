import { forwardRef, Ref } from 'react';
import { Default, DefaultProps } from './Default';

export type TextProps = DefaultProps;

const Text = forwardRef((props: TextProps, ref: Ref<HTMLButtonElement>) => {
    const { className, ...others } = props;
    const c = ` ${className} btn-vincotte-text btn-vincotte-padding`;

    return (
        <Default ref={ref} className={c} {...others} />
    );
});

Text.displayName = 'Text';

export { Text };