import { CSSProperties, forwardRef, HTMLAttributes, Ref } from 'react';

export interface PopUpProps extends HTMLAttributes<HTMLDivElement> {
    modalStyle?: CSSProperties;
}

const defaultStyle: CSSProperties = {
    zIndex: 1040,
    backgroundColor:'rgba(64,64,64,.5',
    width:'100%',
    height:'100%',
    position:'fixed',
    top:'0',
    left:'0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
} as CSSProperties;

const PopUp = forwardRef((props: Readonly<PopUpProps>, ref: Ref<HTMLDivElement>) => {
    const { children, style: styleProps, modalStyle: modalStyleProps = {}, ...rest } = props;

    const style = {
        padding: '2em',
        backgroundColor:'white',
        borderRadius: '4px',
        maxHeight: '100%',
        // overflow: 'auto',
        width:'max-content',
        ...styleProps
    } as CSSProperties;

    const modalStyle = {
        ...defaultStyle,
        ...modalStyleProps
    };

    return (
        <div style={modalStyle} ref={ref}>
            <div style={style} {...rest}>
                {children}
            </div>
        </div>
    );
});

PopUp.displayName = 'PopUp';

export { PopUp };
