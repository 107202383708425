import { forwardRef, Ref, SelectHTMLAttributes, useRef } from 'react';
import { ID } from '../../utils/ID';

export interface SelectGroupRow {
    value: number | string;
    label: string;
}

type SelectGroupProps =  SelectHTMLAttributes<HTMLSelectElement> & {
    label?: string;
    rows: Array<SelectGroupRow>;
    isLarge?: boolean;
}

const createRows = (key: number | string, { value, label }: SelectGroupRow) => (<option key={key} value={value} >{label}</option>);

const SelectGroup = forwardRef((props: Readonly<SelectGroupProps>, ref: Ref<HTMLSelectElement>) => {
    const {
        label,
        className = '',
        placeholder,
        disabled: isDisabled,
        children,
        rows,
        isLarge = true,
        required = false,
        ...rest
    } = props;

    const inputClass = `form-control ${isLarge ? 'form-control-lg' : ''}`;

    const id = useRef(ID());

    const r = required ? '*' : '';

    return (
        <div className="form-group">
            <label htmlFor={id.current}>{label}{r}:</label>

            <select
                id={id.current}
                className={`${className} ${inputClass} ${isDisabled ? 'form-control-disabled' : '' }`}
                disabled={isDisabled}
                ref={ref}
                placeholder={placeholder}
                required={required}
                {...rest}
            >
                {typeof placeholder === 'string' && <option value="" >{placeholder}</option>}
                {rows.map((row, key) => createRows(key, row))}
            </select>
        </div>
    );
});

SelectGroup.displayName = 'SelectGroup';

export { SelectGroup };