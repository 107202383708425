import { AxiosDefaults } from 'axios';
import { ComponentType, createElement } from 'react';
import { Subtract } from 'utility-types';
import { UseAxios } from '../hooks/useAxios';
import { IService, useAxiosService } from '../hooks/useAxiosService';
import { withAxios } from './withAxios';

export type WithAxiosServiceProps<TKey extends string, TValue> = {
    [key in TKey]: TValue;
}

export function withAxiosService<TService>(
    service: IService<TService>,
    propName?: string
) {
    return (<
        TKey extends string,
        TProps extends WithAxiosServiceProps<TKey, TService> = WithAxiosServiceProps<TKey, TService>
    >(WrappedComponent: ComponentType<TProps>, axiosDefaults?: AxiosDefaults) => {
        const handler = (props: Subtract<TProps, WithAxiosServiceProps<TKey, TService>> & UseAxios) => {
            const serviceName = propName ?? service.name;

            const { service: instance } = useAxiosService<TService>(service, axiosDefaults);

            const renderedProps: TProps = {
                ...{ [serviceName]: instance },
                ...props,
            } as TProps;

            return createElement<TProps>(WrappedComponent, renderedProps);
        };

        const name = WrappedComponent.displayName || WrappedComponent.name || 'Component';
        handler.displayName = `withAxiosService()(${name})`;
        handler.WrappedComponent = WrappedComponent;

        return withAxios()(handler);
    });
}
