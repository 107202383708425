import { createContext, ReactNode } from 'react';
import { FileUploadProgressController } from './FileUploadProgressController';

type FielUploadProgressContextProps = {
    controller?: FileUploadProgressController;
}

const FileUploadProgressContext = createContext<FielUploadProgressContextProps | undefined>(undefined);
FileUploadProgressContext.displayName = 'FileUploadProgressContext';

type FileUploadProgressProviderProps = FielUploadProgressContextProps & {
    children: ReactNode;
}

const FileUploadProgressProvider = ({children, ...state} : FileUploadProgressProviderProps ) => (<FileUploadProgressContext.Provider value={state}>{children}</FileUploadProgressContext.Provider>);

export { FileUploadProgressContext, FileUploadProgressProvider };