import { PureComponent } from 'react';
import { ImgIconBase, ImgIconDetailedHTMLProps } from './ImgIconBase';

type ExitFullscreenProps = ImgIconDetailedHTMLProps;

export class ExitFullscreen extends PureComponent<ExitFullscreenProps> {
    render() {
        return (
            <ImgIconBase src='/images/icons/video-player/exit-fullscreen.svg' {...this.props} />
        );
    }
}