import { forwardRef, memo, Ref } from 'react';
import { Text, TextProps } from './Text';

export type PrimaryProps = TextProps;

const Primary = forwardRef((props: PrimaryProps, ref: Ref<HTMLAnchorElement>) => {
    const { title = undefined, isActive = false, className = '', ...others } = props;
    const c = `btn-vincotte-padding btn-vincotte-primary ${className}`;

    return (
        <Text className={c} isActive={isActive} title={title} {...others} />
    );
});

Primary.displayName = 'Primary';

const PurePrimary = memo(Primary);

export { PurePrimary as Primary };
