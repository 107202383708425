import { Component } from 'react';
import { Primary, PrimaryProps } from '../Buttons/Primary';

export type ConfirmButtonProps = PrimaryProps;

export class ConfirmButton extends Component<ConfirmButtonProps> {
    render() {
        const { children, ...rest } = this.props;

        rest.className = rest.className ? `${rest.className } ml-3` : 'ml-3';

        return (<Primary {...rest} >{children}</Primary>);
    }
}