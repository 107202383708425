import { Component } from 'react';
import { WithTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ClientByMonikerVm, IClientsVm, ProductionLineDto, ProductionLineVm, ProductionLinesVm } from '../../api/vincotte.via.api';
import Card from '../../components/Card';
import CardBreadcrumbs from '../CardBreadcrumbs';
import { WithRouterProps, withRouter } from '../../HOC/withRouter';
import { ProductionUnitMenu } from './ProductionUnitMenu';

interface ProductionUnitProps extends Pick<WithTranslation, 't'>,
    WithRouterProps {
    productionUnit: ProductionLinesVm | ProductionLineDto | ProductionLineVm;
    company?: IClientsVm | ClientByMonikerVm;
    onDeleteSuccess?: () => void;
}

class ProductionUnit extends Component<ProductionUnitProps> {

    get customer() : IClientsVm | ClientByMonikerVm | undefined {
        const { productionUnit } = this.props;
        let { company } = this.props;

        if (!company && productionUnit instanceof ProductionLinesVm) {
            company = {
                name: productionUnit.clientName,
                moniker: productionUnit.clientMoniker
            };
        }

        return company;
    }

    render() {
        const { productionUnit, t, onDeleteSuccess } = this.props;
        const { id, name: title } = productionUnit;

        if (!id) {
            return null;
        }

        const client = this.customer;

        const productionUnitLink = `/production-unit/${id}`;

        return (
            <Card backgroundColor='production-unit' >
                <ProductionUnitMenu t={t} productionUnit={productionUnit} onDeleteSuccess={onDeleteSuccess} />
                <nav>
                    <CardBreadcrumbs company={client} />

                    <h3 className='mt-3'>
                        <Link to={productionUnitLink} >{title}</Link>
                    </h3>
                </nav>
            </Card>
        );
    }
}


const ProductionUnitMenuWithRouter = withRouter<ProductionUnitProps>(ProductionUnit);

export { ProductionUnitMenuWithRouter as ProductionUnit };
