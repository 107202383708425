export class ApiResponse {
    constructor(ok: boolean, response: any = undefined, status: number | undefined = undefined, error: string | undefined = undefined) {
        this._ok = ok;
        this._response = response;
        this._status = status;
        this._error = error;
    }

    static async createInstance(response: ApiResponse | XMLHttpRequest | Response) {
        if (response instanceof ApiResponse) {
            return response;
        }

        if (response instanceof XMLHttpRequest) {
            return new ApiResponse(
                (response.readyState !== undefined && response.readyState === 4 && response.status >= 200 && response.status <= 299),
                response.response,
                response.status,
            );
        }

        if (response instanceof Response) {
            return response.text().then((text) => {
                const ok = response.ok;

                return new ApiResponse(
                    ok,
                    text,
                    response.status,
                    ok ? undefined : response.statusText,
                );
            });
        }

        throw new Error(`Instance of ${typeof response} is not supported`);
    }

    static createErrorInstance(message: string) {
        return new ApiResponse(false, undefined, undefined, message);
    }

    private readonly _status?: number;
    get status() : number | undefined {
        return this._status;
    }

    private readonly _ok: boolean;
    get ok() : boolean {
        return this._ok;
    }

    private readonly _response?: string;
    get response() : string | undefined {
        return this._response;
    }

    private readonly _error?: string;
    get error() : string | undefined {
        return this._error;
    }

    async json() {
        return new Promise((resolve, reject) => {
            this.response ? resolve(JSON.parse(this.response)) : reject('response is empty');
        });
    }

    createError = () => {
        const error = new Error(this.response);
        if (this.error) {
            error.name = this.error;
        }

        if (error.name.length === 0) {
            switch(this.status) {
            case 409:
                error.name = 'Conflict';
                break;
            }
        }

        return error;
    }
}
