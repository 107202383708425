import { forwardRef, memo, Ref } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

export type DefaultProps = NavLinkProps & {
    isActive?: boolean;
};

const Default = forwardRef((props: DefaultProps, ref: Ref<HTMLAnchorElement>) => {
    const { className = '', to, isActive = false, ...others } = props;

    return (
        <NavLink to={to} className={(navProps) => [
            'btn round btn-vincotte',
            typeof className === 'function' ? className(navProps) : className ,
            isActive ? 'active' : ''
        ].join(' ')} {...others} ref={ref} />
    );
});

Default.displayName = 'Default';

const PureDefault = memo(Default);

export { PureDefault as Default };
