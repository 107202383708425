import { PureComponent, ReactNode } from 'react';

type ButtonLableProps = {
    children?: ReactNode;
}

export class ButtonLabel extends PureComponent<ButtonLableProps> {
    render() {
        return (<>{this.props.children}</>);
    }
}
