import { Component } from 'react';
import { Cancel, CancelProps } from '../Buttons/Cancel';

export type CancelButtonProps = CancelProps;

class CancelButton extends Component<CancelButtonProps> {
    render() {
        return (<Cancel {...this.props} />);
    }
}

export { CancelButton };
