import { forwardRef, ReactNode, Ref, TextareaHTMLAttributes, useRef } from 'react';
import { ID } from '../../utils/ID';
import './formElements.css';

type TextareaGroupProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
    label?: string;
}

const TextareaGroup = forwardRef((props: Readonly<TextareaGroupProps>, ref: Ref<HTMLTextAreaElement>) => {
    const {
        label,
        disabled: isDisabled,
        className,
        required = false,
        ...rest
    } = props;

    const id = useRef(ID());
    const r = required ? '*' : '';

    return (
        <div className="form-group">
            {label ? <label htmlFor={id.current}>{label}{r}: </label> : null}

            <textarea
                ref={ref}
                className={`${className} form-control ${isDisabled && 'form-control-disabled' }`} // add conditional for when is disabled to have some other style if i am not wrong ( costa comment)
                id={id.current}
                rows={6}
                disabled={isDisabled}
                required={required}
                {...rest}
            />
        </div>
    );
});

TextareaGroup.displayName = 'TextareaGroup';

export { TextareaGroup };