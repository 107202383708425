import { PureComponent } from 'react';
import { ImgIconBase, ImgIconDetailedHTMLProps } from './ImgIconBase';

type FilterProps = ImgIconDetailedHTMLProps;

export class Filter extends PureComponent<FilterProps> {
    render() {
        return (
            <ImgIconBase src='/images/icons/filter.svg' {...this.props} />
        );
    }
}