import { PureComponent, memo } from 'react';

export type ImgIconDetailedHTMLProps = React.ImgHTMLAttributes<HTMLImageElement>;

export interface ImgIconBaseProps extends ImgIconDetailedHTMLProps {
    src: string,
}

function ImgIconBase(props: ImgIconBaseProps) {
    return (
        <img  {...props} />
    );
}

const ImgIconBaseProp = memo(ImgIconBase);

export { ImgIconBaseProp as ImgIconBase };