import { PureComponent, ReactNode } from 'react';
import { File as CoreFile } from '../../core/File';
import { FileLine } from './FileLine';

type FileListProps = {
    children: ReactNode;
    files?: Array<CoreFile>;
    onDelete?: (file: CoreFile) => void;
    onClick?: (file: CoreFile) => boolean;
    permissionsToDelete?: Array<string>;
}

class FileList extends PureComponent<FileListProps> {
    render() {
        const { files, children, onDelete, onClick, permissionsToDelete = [] } = this.props;

        return (
            <table className='table table-borderless table-inspection'>
                <tbody>{
                    files && files.length > 0
                        ? files.map((file: CoreFile, index: number) => <FileLine file={file} key={index} onClick={onClick} onDelete={onDelete} permissionsToDelete={permissionsToDelete} />)
                        : children
                }</tbody>
            </table>
        );
    }
}

export { FileList };
