import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ImgIconBase } from './ImgIconBase';

class Trash extends PureComponent {
    render() {
        return (
            <ImgIconBase src='/images/icons/trash.svg' {...this.props} />
        );
    }
}

Trash.propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
};

export { Trash };