export class FileHelper {
    static async toBase64(blob: Blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error: ProgressEvent<EventTarget>) => reject(error);
        });
    }

    static getFileExtension(mediaLink: URL | string): string {
        const link = decodeURIComponent(mediaLink instanceof URL ? mediaLink.pathname : mediaLink);

        let name = link.split('\\').pop();
        name = name?.split('?').shift();
        name = name?.split('/').pop();
        name = name?.split('.').pop();

        return name ? name : '';
    }
}
