import 'bootstrap/dist/css/bootstrap.min.css';
// import { hot } from 'react-hot-loader/root';
import { AppState, Auth0Provider, User } from '@auth0/auth0-react';
import { useEffect, useRef } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Callback from '../components/Callback';
import ProtectedRoute from '../components/ProtectedRoute';
import '../i18n';
import FileDownload from '../pages/FileDownload';
import { ViA } from './ViA';

const App = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const onRedirectCallback = (appState?: AppState, user?: User) => {
        navigate(appState?.returnTo || location.pathname);
    };

    const clarityRef = useRef(false);

    useEffect(() => {
        if (clarityRef.current) {
            return;
        }

        clarityRef.current = true;

        if (process.env.REACT_APP_CLARITY_ENABLED === 'true') {
            const clarity = `
            (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "${process.env.REACT_APP_CLARITY_PROJECT_ID}");`;

            new Function(clarity)();
        }
    }, []);

    return (
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ''}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''}
            authorizationParams={{
                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                scope: 'openid profile email access:all',
                redirect_uri: process.env.REACT_APP_AUTH0_CALLBACK_URL
            }}
            onRedirectCallback={onRedirectCallback}
            cacheLocation='memory'
            // useRefreshTokens={true}
        >
            <Routes>
                <Route path='/files/download/:fileId/:fileName' element={
                    <ProtectedRoute component={FileDownload} />
                } />

                <Route path='/callback' element={<Callback />} />

                <Route path='*' element={<ViA />} />
            </Routes>
        </Auth0Provider>
    );
};

// const AppHot = hot(App);

// export { AppHot as App };
export { App };
