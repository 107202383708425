import { MouseEvent, PureComponent, ReactNode } from 'react';
import { WithRouterProps, withRouter } from '../../HOC/withRouter';

type CardProps = WithRouterProps & {
    children?: ReactNode;
    backgroundColor?: 'white' | 'dark-blue' | 'company' | 'production-unit' | 'asset' | 'inspection' | undefined;
    className?: string;
    link?: string;
    maximumColumns?: 1 | 2 | 3 | 4;
}

class Card extends PureComponent<CardProps> {
    onClick = (e: MouseEvent<HTMLDivElement>) => {
        const { link, navigate } = this.props;

        if (!link) {
            return;
        }

        if (e.cancelable) {
            e.preventDefault();
            e.stopPropagation();
        }

        navigate(link);
    }

    get role() {
        return this.props.link ? 'button' : undefined;
    }

    get className() {
        const { maximumColumns = 4 } = this.props;

        const large = maximumColumns > 1 ? maximumColumns - 1 : 1;
        const medium = large > 1 ? large - 1 : 1;

        return `col-12 col-sm-12 col-md-${12 / medium} col-lg-${12 / large} col-xl-${12 /  maximumColumns}`;
    }

    render() {
        const { backgroundColor: bgColor = 'white', className = '', children } = this.props;

        return (
            <div className={`${this.className} pb-3 pr-3 model`} onClick={this.onClick}>
                <div className={`card shadow bg-vincotte-${bgColor} ${className}`} data-testid='card-background' >
                    <div className='card-body' data-testid='card-children' >{children}</div>
                </div>
            </div>
        );
    }
}

const CardWithRouter = withRouter(Card);
export { CardWithRouter as Card };
