import { ButtonHTMLAttributes, forwardRef, Ref } from 'react';

export type DefaultProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    isActive?: boolean;
}

const Default = forwardRef((props: DefaultProps, ref: Ref<HTMLButtonElement>) => {
    const { children, className, isActive = false, type = 'button', ...others } = props;

    let c = `btn round btn-vincotte ${className}`;
    if (isActive) {
        c += ' active';
    }

    return (<button ref={ref} type={type} className={c} {...others} >{children}</button>);
});

Default.displayName = 'Default';

export { Default };
