import { Component } from 'react';
import { ImgIconBase } from './ImgIconBase';

class Download extends Component {
    render() {
        return (
            <ImgIconBase src='/images/icons/download.svg' {...this.props} />
        );
    }
}

export { Download };