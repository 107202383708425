import { PureComponent } from 'react';
import { ImgIconBase, ImgIconDetailedHTMLProps } from './ImgIconBase';

type FullscreenProps = ImgIconDetailedHTMLProps;

export class Fullscreen extends PureComponent<FullscreenProps> {
    render() {
        return (
            <ImgIconBase src='/images/icons/video-player/fullscreen.svg' {...this.props} />
        );
    }
}