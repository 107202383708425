import { ReactNode, memo } from 'react';
import { MainMenu as MainMenuButton } from '../NavButtons/MainMenu';
// import { ProtectedElement } from '../ProtectedElement/ProtectedElement';
import { useTranslation } from 'react-i18next';
import { DashboardIcon } from '../Icons/DashboardIcon';
import { Library } from '../Icons/Library';
import ProtectedElement from '../ProtectedElement';

interface MainMenuProps {
    isMenuMinimized?: boolean;
}

const navbarClass = 'nav flex-column flex-nowrap overflow-auto text-white nav-bar';

function MainMenu({ isMenuMinimized }: MainMenuProps) {
    const { t } = useTranslation();

    return (
        <nav className={navbarClass} >
            <div>
                <ul className='nav flex-column'>
                    <li className='nav-item'>
                        <MainMenuButton to='/' activeWhenMatch={true} >
                            <DashboardIcon/>
                            {(!isMenuMinimized && t('components.NavigationBar.Home')) as ReactNode}
                        </MainMenuButton>
                    </li>

                    <ProtectedElement permissions={['CanReadSampleModel']}>
                        <li className='nav-item'>
                            <MainMenuButton to='/library' activeWhenMatch={true}>
                                <Library/>
                                {(!isMenuMinimized && t('components.NavigationBar.Library')) as ReactNode}
                            </MainMenuButton>
                        </li>
                    </ProtectedElement>
                </ul>
            </div>
        </nav>
    );
}

const MainMenuMemo = memo(MainMenu);

export { MainMenuMemo as MainMenu };
