import { IClientByMonikerVm, IClientsVm } from '../../api/vincotte.via.api';
import CompanyLogo from '../CompanyLogo';
import { AutoCol, AutoRow } from '../Layout';
import './PageTitle.css';

interface PageTitleProps {
    logoSrc?: string;
    company?: IClientByMonikerVm | IClientsVm;
    title?: string;
}

function PageTitle({ logoSrc, company, title }: PageTitleProps) {
    return (
        <AutoRow className='page-title'>
            <AutoCol><CompanyLogo src={logoSrc} company={company} /></AutoCol>
            <AutoCol><h1 className='m-0'>{title ?? ''}</h1></AutoCol>
        </AutoRow>
    );
}

export { PageTitle };