import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingIndicator from '../LoadingIndicator';
import ProtectedElement from '../ProtectedElement';
// import { MainMenu as MainMenuButton } from '../NavButtons';
import { useAuth0 } from '@auth0/auth0-react';
import { Settings } from './Settings';

interface ProfileMenuProps {
    isMenuMinimized: boolean;
    setMinimized?: VoidFunction;
}

function ProfileMenu({ isMenuMinimized = false, setMinimized = () => {} }: ProfileMenuProps) {
    const { t  } = useTranslation();
    const { isAuthenticated, user } = useAuth0();

    const [ showSettings, setShowSettings ] = useState<boolean>(false);

    const toggleSettings = useMemo(() => () => setShowSettings((settings) => !settings), []);

    if (isAuthenticated && !user) {
        return <LoadingIndicator />;
    }

    let firstName;
    let profilePicture;
    if (isAuthenticated && user) {
        firstName = user.given_name && user.given_name.length > 0
            ? user.given_name.trim()
            : user.name?.split(' ').shift();

        profilePicture = user.picture;
    }

    return (
        <div className='profile' >
            <div className='nav-item nav-link nav-item-border-bottom' onClick={setMinimized}>
                <svg width="40" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="10.5" width="23" height="13" stroke="black"/>
                    <rect x="0.5" y="10.5" width="4" height="13" stroke="black"/>
                    <rect x="17.5" y="10.5" width="6" height="13" stroke="black"/>
                    <path d="M1 4.5H23" stroke="black"/>
                    <path d="M20 1L23 4.5L20 8" stroke="black"/>
                    <path d="M4 8L1 4.5L4 1" stroke="black"/>
                </svg>

                {!isMenuMinimized &&  t('components.NavigationBar.Minimize') }
            </div>

            <ProtectedElement>
                <div className='nav-item' style={{paddingTop:'1em'}} onMouseEnter={toggleSettings} onMouseLeave={toggleSettings}>
                    <div style={{width:'48px', height:'48px', display:'inline-block'}}>
                        {Boolean(profilePicture) && <img src={profilePicture} style={{ maxWidth: 20, maxHeight: 20, marginLeft: 11, marginRight: 8 }} />}
                    </div>

                    {!isMenuMinimized  && firstName}

                    {showSettings && <Settings isMenuMinimized={isMenuMinimized} /> }
                </div>
            </ProtectedElement>

        </div>
    );
}

const ProfileMenuWithMemo = memo(ProfileMenu);

export { ProfileMenuWithMemo as ProfileMenu };
