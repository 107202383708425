import { PureComponent } from 'react';
import { Text as TextButton, TextProps as TextButtonProps } from './Text';

export type PrimaryProps = TextButtonProps;

class Primary extends PureComponent<PrimaryProps> {
    render() {
        const { className, ...rest } = this.props;

        return (
            <TextButton className={`btn-vincotte-padding btn-vincotte-primary ${className}`} {...rest} />
        );
    }
}
export { Primary };