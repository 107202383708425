import { Observable } from '@babylonjs/core/Misc/observable';


/**
 * The internet verification is a Singleton class
 * Use `getInstance` method that lets clients access
 */
export class InternetStatusClient implements Disposable {
    private static instance?: InternetStatusClient;
    #_worker: SharedWorker | undefined;
    #_channel: BroadcastChannel | undefined;
    #_isOnline: boolean;

    get IsOnline() {
        return this.#_isOnline;
    }

    private constructor() {
        this.#_isOnline = window.navigator.onLine;
    }

    /**
     * The static method that controls the access to the singleton instance.
     *
     * This implementation let you subclass the InternetStatusClient class while keeping
     * just one instance of each subclass around.
     */
    public static getInstance(): InternetStatusClient {
        if (!InternetStatusClient.instance) {
            InternetStatusClient.instance = new InternetStatusClient();
        }

        return InternetStatusClient.instance;
    }

    isConnected = (isOnline: boolean) => {
        if (this.#_isOnline === isOnline) {
            return;
        }

        this.#_isOnline = isOnline;

        InternetStatusClient.OnStatusChange.notifyObservers({ isOnline: this.#_isOnline });
    }

    public start(accessToken: string) {
        if (!this.#_worker) {

            this.#_channel = new BroadcastChannel('internet-status');
            this.#_channel.addEventListener('message', (e) => this.isConnected(e.data.isOnline));

            this.#_worker = new SharedWorker('/internet-status-worker.bundle.js', { name: 'ConnectionDectector' });
        }
    }

    public terminate() {
        this.#_worker = undefined;
    }

    public static readonly OnStatusChange: Observable<OnInternetStatusChange> = new Observable<OnInternetStatusChange>();

    [Symbol.dispose]() {
        this.terminate();
        InternetStatusClient.instance = undefined;
    }
}

export interface OnInternetStatusChange {
    isOnline: boolean;
}