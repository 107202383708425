export class ColorHelper {
    static hslToHex(hue: number, saturation: number, lightness: number): string {
        if (hue < 0 || hue > 360) {
            throw new Error(`Hue parameter is out of range. The value must be between 0 and 360. Value received: ${hue}`);
        }

        if (saturation < 0 || saturation > 100) {
            throw new Error(`Saturation parameter is out of range. The value must be between 0 and 100. Value received: ${saturation}`);
        }

        if (lightness < 0 || lightness > 100) {
            throw new Error(`Lightness parameter is out of range. The value must be between 0 and 100. Value received: ${lightness}`);
        }

        const h = hue / 360;
        const s = saturation / 100;
        const l = lightness / 100;

        if (s === 0) {
            // achromatic
            const achromatic = ColorHelper.lightToBase16(l);
            return `#${achromatic}${achromatic}${achromatic}`;
        }
        const hue2rgb = (p: number, q: number, t: number) => {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        };

        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        const r = hue2rgb(p, q, h + 1 / 3);
        const g = hue2rgb(p, q, h);
        const b = hue2rgb(p, q, h - 1 / 3);

        return `#${ColorHelper.lightToBase16(r)}${ColorHelper.lightToBase16(g)}${ColorHelper.lightToBase16(b)}`;
    }

    static lightToBase16(intensity: number) : string {
        if (intensity < 0 || intensity > 1) {
            throw new Error(`Intensity is out of range. The value must be between 0 and 1. Value received: ${intensity}`);
        }

        const hex = Math.round(intensity * 255).toString(16).toUpperCase();
        return hex.length === 1 ? `0${hex}` : hex;
    }
}
