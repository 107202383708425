export class MathHelper {
    static percentageOf(value: number, percentage: number) : number {
        return value * (percentage / 100);
    }

    static precision(number: number) : number {
        if (!isFinite(number)) {
            return 0;
        }

        let e = 1, p = 0;

        while (Math.round(number * e) / e !== number) {
            e *= 10;
            p++;
        }

        return p;
    }

    static inputNumberRound(number: number | string, step: number) : number {
        const num = typeof number === 'string' ? parseFloat(number.replace(/,/, '.')) : number;
        const prec = MathHelper.precision(step);
        if (prec === 0) {
            return num;
        }

        return MathHelper.numberRound(num, prec);
    }

    static numberRound(number: number, fractionDigits: number) : number {
        const precisionRounder = Math.pow(10, fractionDigits);
        // return Math.floor(number * precisionRounder) / precisionRounder;
        // todo: find a faster solution
        return parseInt((number * precisionRounder).toFixed(0)) / precisionRounder;
    }
}