export class RadiansHelper {
    static shortestRotation(from: number, to: number) : number {
        const fullRotation = Math.PI * 2;
        const halfRotation = Math.PI;
        const normalizedTo = to > 0 ? to : fullRotation + to;

        const isFromNegative = from < 0;
        const timesRotated = isFromNegative ? Math.ceil(from / fullRotation) : Math.floor(from / fullRotation);
        let relativeTo = (fullRotation * timesRotated) + normalizedTo;

        const isAlphaHigherThanPI = Math.abs(from - relativeTo) > halfRotation;
        if (isAlphaHigherThanPI) {
            relativeTo -= (fullRotation * (timesRotated + 1));
        }

        return relativeTo;
    }
}
