
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { useState } from 'react';
import { useAuthentication } from './useAuthentication';

export interface UseAxios {
    baseURL?: string;
    axios?: AxiosInstance;
}

export function useAxios() : UseAxios {
    const baseURL = process.env.REACT_APP_API_URL;

    // Default config options
    const defaultOptions: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    // Create instance
    const instance: AxiosInstance = axios.create(defaultOptions);

    const { auth } = useAuthentication();

    // Set the AUTH token for any request
    instance.interceptors.request.use((config) => {
        if (auth) {
            const token = auth.accesToken;
            config.headers.Authorization =  token ? `Bearer ${token}` : '';
        }
        return config;
    });

    const [state] = useState<Record<string, AxiosInstance>>({
        axios: instance
    });

    return {
        axios: state.axios,
        baseURL: baseURL,
    } as UseAxios;
}