import { forwardRef, memo, Ref } from 'react';
import { Default, DefaultProps } from './Default';

export type TextProps = DefaultProps;

const Text = forwardRef((props: TextProps, ref: Ref<HTMLAnchorElement>) => {
    const { className = '', to, isActive, ...others } = props;

    const c = `btn-vincotte-text btn-vincotte-padding ${className}`;

    return (
        <Default to={to} isActive={isActive} className={c} ref={ref} {...others} />
    );
});

Text.displayName = 'Text';

const PureText = memo(Text);

export { PureText as Text };
