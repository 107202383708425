import { useEffect } from 'react';
import { PreventWindowUnload } from '../../utils/PreventWindowUnload';

const useLoadingIndicatorHooks = (context: any) => {
    useEffect(() => {
        const componentId: string = PreventWindowUnload.generateId(context);
        PreventWindowUnload.prevent(componentId, context);

        return () => PreventWindowUnload.release(componentId);
    }, []);
};

export { useLoadingIndicatorHooks };