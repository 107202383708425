import { PureComponent, ReactNode } from 'react';

type EmptyListProps = {
    children?: ReactNode;
}

export class EmptyList extends PureComponent<EmptyListProps> {
    render() {
        const { children } = this.props;

        return (
            <tr>
                <td scope='row'  style={{ lineHeight: '42px' }} className='table-light rounded'>{children}</td>
            </tr>
        );
    }
}