import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ImgIconBase } from './ImgIconBase';

class Online extends PureComponent {
    render() {
        return (
            <ImgIconBase src='/images/icons/online.svg' {...this.props} />
        );
    }
}

Online.propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
};

export { Online };