import { Fragment, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { IAssetsByProductionLineVm, IClientByMonikerVm, IClientsVm, IProductionLineDto, IProductionLineVm, IProductionLinesVm } from '../../api/vincotte.via.api';
import CompanyLogo from '../CompanyLogo';
import { AutoCol, AutoRow } from '../Layout';
import './CardBreadcrumbs.css';
import { TitleHelper } from '../../utils/TitleHelper';

type CardBreadcrumbsProps = {
    className?: string;
    company?: IClientByMonikerVm | IClientsVm;
    productionUnit?: IProductionLinesVm | IProductionLineDto | IProductionLineVm;
    asset?: IAssetsByProductionLineVm;
    showClientLink?: boolean;
};

type Breadcrumb = {
    title: string;
    to: string;
}

const CardBreadcrumbs = (props: CardBreadcrumbsProps) => {
    const {
        className = '',
        company,
        productionUnit,
        asset,
        showClientLink = true } = props;

    const breadcrumbs = new Array<Breadcrumb>();

    if (company && showClientLink) {
        breadcrumbs.push({
            to: `/company/${company.moniker}`,
            title: company.name
        } as Breadcrumb);
    }

    if (productionUnit) {
        breadcrumbs.push({
            to: `/production-unit/${productionUnit.id ?? ''}`,
            title: productionUnit.name
        } as Breadcrumb);
    }

    if (asset) {
        breadcrumbs.push({
            to: `/asset/${asset.id}`,
            title: TitleHelper.FormatAssetTitle(asset),
        } as Breadcrumb);
    }

    const c = `m-0 ${className}`;

    return (
        <AutoRow className='card-breadcrumbs' >
            {Boolean(company) && <AutoCol className='pr-2'><CompanyLogo company={company as IClientByMonikerVm | IClientsVm} /></AutoCol>}

            <AutoCol>{
                breadcrumbs.map(({to, title}: Breadcrumb, index: number) => {
                    return (<Fragment key={title}>
                        {index > 0 ?? <> / </>}

                        <Link to={to} title={title} className={c} >{title}</Link>
                    </Fragment>);
                }) as ReactNode}
            </AutoCol>
        </AutoRow>

    );
};

CardBreadcrumbs.displayName = 'CardBreadcrumbs';

export { CardBreadcrumbs };
