import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Checkbox } from './Checkbox';

type CheckboxGroupRow = {
    key?: string | number;
    value: string | number;
    label: string;
    checked: boolean;
}

type CheckboxGroupProps = WithTranslation & {
    label: string;
    name: string;
    rows: Array<CheckboxGroupRow>;
    required?: boolean;
    requiredMessage?: string;
}

class CheckboxGroup extends Component<CheckboxGroupProps> {
    row = (name: string, key: string | number, row: CheckboxGroupRow, rest = {}) => {
        const { value, label, checked = false } = row;
        const inputId = `${name}-${value}`;

        return (
            <li key={key} className="list-group-item">
                <Checkbox
                    id={inputId} defaultChecked={checked}
                    value={value}
                    type="checkbox"
                    name={name}
                    label={label}
                    {...rest} />
            </li>
        );
    }

    render() {
        const {
            t,
            label,
            name,
            rows,
            required = false,
            requiredMessage = t('pages.NewInspection.Choose at least one'),
            ...rest
        } = this.props;

        return (
            <div className="form-group">
                <label className="col-form-label col-form-label-lg">{label}: </label>
                {required && <div className="bg-danger">{requiredMessage}</div>}

                <ul className="list-group text-dark">{rows.map(
                    (row, key) => this.row(name, key, row, rest)
                )}</ul>
            </div>
        );
    }
}

const CheckboxGroupWithTranslation = withTranslation()(CheckboxGroup);
export { CheckboxGroupWithTranslation as CheckboxGroup };