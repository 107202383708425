import { PureComponent } from 'react';
import { ImgIconBase, ImgIconDetailedHTMLProps } from './ImgIconBase';

type Reverse10Props = ImgIconDetailedHTMLProps;

export class Reverse10 extends PureComponent<Reverse10Props> {
    render() {
        return (
            <ImgIconBase src='/images/icons/video-player/reverse-10.svg' {...this.props} />
        );
    }
}