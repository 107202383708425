import { Component, HTMLAttributes } from 'react';

type MessageProps = HTMLAttributes<HTMLParagraphElement>;

export class Message extends Component<MessageProps> {
    render() {
        return (
            <p {...this.props} />
        );
    }
}