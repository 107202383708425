import { ChangeEventHandler, Component, CSSProperties, PureComponent } from 'react';
import { WithTranslation } from 'react-i18next';

type ItemToDeleteSummary = Record<string, any>;

export type ItemToDelete = {
    id: number;
    reference: string;
    name: string;
    summary?: ItemToDeleteSummary;
}

export type ItemToDeleteState = ItemToDelete & {
    checked: boolean;
}

type DeleteItemProps = Pick<WithTranslation, 't'> & {
    item: ItemToDeleteState;
    onCheckChanged: ChangeEventHandler<HTMLInputElement>;
};

export class DeleteItem extends Component<DeleteItemProps> {

    private readonly style: CSSProperties = {
        display:'grid',
        gridTemplateColumns:'30px 1fr minmax(200px, 1fr)',
        alignItems:'center',
        border:'1px solid #A0B7C1',
        borderRadius:'4px',
        padding:'1em ',
        marginBottom:'1em',
        gap:'2em',
    };

    getRelatedData = (summary: ItemToDeleteSummary) => {
        const style = { margin: '0.5rem 0' };

        const relatedData = Object.keys(summary).filter((name) => summary[name] > 0).sort().map((name, key) => {
            return <p key={key} style={style}>{name}: {summary[name]}</p>;
        });

        if (relatedData.length > 0) {
            return relatedData;
        }

        const { t } = this.props;

        return <p style={style}>{t('components.DeleteCard.No related data associated')}</p>;
    }

    render() {
        const { item: item, onCheckChanged } = this.props;
        const { name, checked, summary } = item;

        let relatedData = undefined;
        if (summary) {
            relatedData =  <div>{this.getRelatedData(summary)}</div>;
        }

        return (
            <div style={this.style}>
                <input type="checkbox" className='checkbox-size' checked={checked} onChange={onCheckChanged} />
                <p style={{margin:'0', maxWidth:'350px'}} className="bolder">{name}</p>

                {relatedData}
            </div>
        );
    }
}