import { Component, memo, useMemo, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation, withTranslation } from 'react-i18next';
import { lang } from '../../i18n/index';
import { MainMenu as MainMenuButton } from '../Buttons/MainMenu';
import { ArrowDown } from '../Icons/ArrowDown';

interface LanguageSelectorProps {
    isMenuMinimized?: boolean;
}

function LanguageSelector({ isMenuMinimized }: LanguageSelectorProps) {
    const { t, i18n } = useTranslation();
    const [ isOpen, setIsOpen ] = useState(false);

    const toggleMenu = useMemo(() => () => setIsOpen((is) => !is), []);

    const currentLanguage = lang.filter((lng) => lng.lng === i18n.language)[0];
    if (!currentLanguage) {
        return null;
    }

    const flagStyle = { marginLeft: 12, marginRight: 11 };

    return (
        <>
            <MainMenuButton onClick={toggleMenu} title={t(`components.NavigationBar.${currentLanguage.alt}`)} >
                <ReactCountryFlag
                    countryCode={currentLanguage.country}
                    svg={true}
                    style={flagStyle} />

                {isMenuMinimized ? null : t(`components.NavigationBar.${currentLanguage.name}`)}
                <ArrowDown style={{ float: 'right', marginTop: 13, width: 30 }} />
            </MainMenuButton>

            {isOpen && lang.map(({ lng, country, name, alt }, key) => {
                const changeLanguage = () => {
                    i18n.changeLanguage(lng);
                    toggleMenu();
                };

                return (
                    <MainMenuButton key={key} onClick={changeLanguage} title={t(`components.NavigationBar.${alt}`)} >
                        <ReactCountryFlag countryCode={country} svg={true} style={flagStyle} />
                        {isMenuMinimized ? null : t(`components.NavigationBar.${name}`)}
                    </MainMenuButton>
                );
            })}
        </>
    );
}

const LanguageSelectorWithMemo = memo(LanguageSelector);

export { LanguageSelectorWithMemo as LanguageSelector };
