import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import InternetStatus from '../InternetStatus';
import ProtectedElement from '../ProtectedElement';
import { MainMenu } from './MainMenu';
import './NavigationBar.css';
import { ProfileMenu } from './ProfileMenu';

interface NavigationBarProps {
    isMenuMinimized?: boolean;
    setMinimized?: () => void;
}

function NavigationBar({ isMenuMinimized = false, setMinimized }: NavigationBarProps) {
    const CN = isMenuMinimized ? 'navigationbar--minimized' : '';

    return (
        <ProtectedElement>
            <div  className={`bg-white position-fixed shadow rounded vh-100 navigationbar ${CN}`} >
                <div className='topNavigation'>
                    <NavLink to='/' >
                        <div id="via-logo-bloc" className="nav-item-border-bottom">
                            <img src="/images/via-logo.svg" className="via-logo" alt="Vinçotte" />
                        </div>
                    </NavLink>
                    <InternetStatus isMenuMinimized={isMenuMinimized}/>
                </div>
                <MainMenu isMenuMinimized={isMenuMinimized} />

                <ProfileMenu isMenuMinimized={isMenuMinimized} setMinimized={setMinimized}/>
            </div>
        </ProtectedElement>
    );
}

const NavigationBarMemo = memo(NavigationBar);

export { NavigationBarMemo as NavigationBar };
