import { forwardRef, memo, Ref } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

export type MainMenuProps = NavLinkProps & {
    activeWhenMatch: boolean;
};

const MainMenu = forwardRef((props: MainMenuProps, ref: Ref<HTMLAnchorElement>) => {
    const { className, activeWhenMatch = false,  ...others } = props;

    return (
        <NavLink className={({ isActive }) => [
            'nav-link',
            className,
            activeWhenMatch && isActive ? 'nav-link-active' : ''
        ].join(' ')} ref={ref} {...others} />
    );
});

MainMenu.displayName = 'MainMenu';

const PureMainMenu = memo(MainMenu);

export { PureMainMenu as MainMenu };
