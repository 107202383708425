export class FileUploadProgressController {
    constructor(fileProgressSetState) {
        this._fileProgressSetState = fileProgressSetState;
    }

    setState = (state) => {
        this._fileProgressSetState(state);
    }

    add = (id, fileName, abortController) => {
        this.setState(({ files }) => {
            files[id] = {
                fileName: fileName ? fileName : 'File Name',
                completed: 0,
                label: 'Starting',
                abortController,
            };

            return { files };
        });
    }

    cancel = (id) => {
        this.setState(({ files }) => {
            files[id].label = 'Cancelling';

            return { files };
        });

        setTimeout(() => this.remove(id), 2000);
    }

    remove = (id) => {
        this.setState(({ files }) => {
            if (files[id] !== undefined && files[id] !== null) {
                delete files[id];
            }

            return { files };
        });
    }

    update = (id, percentage, loaded, total) => {
        this.setState(({ files }) => {
            if (files[id] !== undefined && files[id] !== null) {
                const metadata = files[id];

                const l = (loaded / 1024).toFixed(2);
                const t = (total / 1024).toFixed(2);

                metadata.completed = percentage;
                metadata.label = `${l}/${t} kb (${percentage}%)`;
            }

            return { files };
        });
    }

    failure = (id) => {
        this.setState(({ files }) => {
            if (files[id] !== undefined && files[id] !== null) {
                const metadata = files[id];
                metadata.label = 'Error';
                metadata.failed = true;
            }

            return { files };
        });

    }
}