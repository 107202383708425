import { ButtonHTMLAttributes, forwardRef, Ref } from 'react';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const Button = forwardRef((props: Readonly<ButtonProps>, ref: Ref<HTMLButtonElement>) => {
    const { className, ...rest } = props;

    return (
        <button className={`btn btn-vincotte btn-vincotte-padding mr-3 rounded ${className}`} {...rest} ref={ref} />
    );
});

Button.displayName = 'Button';

export { Button };
