
import { AbstractApiService } from './AbstractApiService';
import { Auth } from '../auth/Auth';

export class AbstractAuthService extends AbstractApiService {
    constructor(url: URL | string, auth: Auth | null = null) {
        super(url);
        this._auth = auth;
    }

    private _auth: Auth | null;


    set auth(auth: Auth | null) {
        this._auth = auth;
    }

    get auth() : Auth | null {
        return this._auth;
    }

    fetch(input: RequestInfo, requestInit: RequestInit | null = null) {
        const init = requestInit || {};

        init.headers = init.headers || {};
        if (this.auth !== null) {
            (init.headers as Record<string, any>).Authorization = `Bearer ${this.auth.accesToken}`;
        }
        (init.headers as Record<string, any>)['x-offline-mode'] = true;

        return super.fetch(input, init);
    }
}