import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withForwardedRef } from '../../HOC/withForwardedRef';
import ProgressBar from '../ProgressBar';
import { Text as TextButton } from '../Buttons/Text';

class Progress extends Component {
    render() {
        const { t, className, children, forwardedRef, completed, abortController } = this.props;

        const onClick = (e) => {
            e.preventDefault();

            if (confirm(t('components.FileUploadProgress.Do you want to cancel the upload?'))) {
                abortController.abort();
            }
        };

        const hasCancelButton = !!(abortController);

        return (
            <li className={`list-group-item pt-1 pb-0 pr-0 ${className}`} ref={forwardedRef}>
                <div className='row no-gutters' >
                    <div className='col' >
                        <ProgressBar completed={completed} isAnimated={true} >{children}</ProgressBar>
                    </div>

                    {
                        hasCancelButton && !abortController.signal.aborted &&
                        <div className='col text-right' style={{ flex: '0 0 85px' }} >
                            <TextButton onClick={onClick} >{t('components.FileUploadProgress.Cancel')}</TextButton>
                        </div>
                    }
                </div>
            </li>
        );
    }
}

Progress.propTypes = {
    t: PropTypes.func,
    children: PropTypes.any,
    abortController: PropTypes.instanceOf(AbortController),
    className: PropTypes.string,
    completed: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]).isRequired,
    forwardedRef: PropTypes.object,
};

Progress.defaultProps = {
    className: '',
};

const ProgressWithForwardedRef = withForwardedRef()(Progress);
const ProgressWithTranslation = withTranslation()(ProgressWithForwardedRef);
export { ProgressWithTranslation as Progress };