import React, { Children, Component } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { ID } from '../../utils/ID';
import { withForwardedRef } from '../../HOC/withForwardedRef';
import { ProgressList } from './ProgressList';
import { withTranslation } from 'react-i18next';
import { Progress } from './Progress';
import { PreventWindowUnload } from '../../utils/PreventWindowUnload';
import { FailedProgress } from './FailedProgress';
// import { Prompt } from 'react-router';

class UploadProgress extends Component {

    get uploadProgress() {
        return this._uploadProgress;
    }

    set uploadProgress(uploadProgress) {
        this._uploadProgress = uploadProgress;
    }

    constructor(props) {
        super(props);

        this.uploadProgress = document.createElement('div');
        this.uploadProgress.classList.add('file-upload-progress');
        // this.uploadProgress.classList.add('d-none');
        this.uploadProgress.classList.add('offset-4');
        this.uploadProgress.classList.add('rounded');
        this.uploadProgress.classList.add('col-4');
        this.uploadProgress.classList.add('fixed-top');
        // The <div> is a necessary container for our
        // content, but it should not affect our layout.
        // Only works in some browsers, but generally
        // doesn't matter since this is at
        // the end anyway. Feel free to delete this line.
    }

    componentDidMount() {
        this.uploadProgress.id = this.props.uploadProgressId;
        document.body.appendChild(this.uploadProgress);

        this.componentId = PreventWindowUnload.generateId(this);
        PreventWindowUnload.prevent(this.componentId, this);
    }

    componentWillUnmount() {
        PreventWindowUnload.release(this.componentId);
        document.body.removeChild(this.uploadProgress);
    }

    render() {
        const { children, position, forwardedRef } = this.props;

        const childrenArray = Children.toArray(children);
        const progressChildren = childrenArray.filter((child) => child.type === Progress);
        const failedProgressChildren = childrenArray.filter((child) => child.type === FailedProgress);
        const everyoneElseChildren = childrenArray.filter((child) => child.type !== Progress && child.type !== FailedProgress);

        return createPortal(
            <>
                {failedProgressChildren}

                <ProgressList position={position} className='show-menu' ref={forwardedRef} >
                    {/* <Prompt message={t('components.FileUploadProgress.There is as file being uploaded. Are you sure you want to leave?')} /> */}
                    {progressChildren}
                </ProgressList>

                {everyoneElseChildren}
            </>,
            this.uploadProgress,
        );
    }
}

UploadProgress.propTypes = {
    t: PropTypes.func.isRequired,
    uploadProgressId: PropTypes.string,
    children: PropTypes.any,
    forwardedRef: PropTypes.object,
    position: PropTypes.shape({
        top: PropTypes.string,
        left: PropTypes.string,
    })
};

UploadProgress.defaultProps = {
    uploadProgressId: ID(),
    context: {},
    position: { top: '0', left: '0' }
};

const UploadProgressWithForwardedRef = withForwardedRef()(UploadProgress);
const UploadProgressWithTranslation = withTranslation()(UploadProgressWithForwardedRef);
export { UploadProgressWithTranslation as UploadProgress };