import { IAssetDTO, ISafetyDeviceVm, ISafetyDevicesByAssetVm } from '../api/vincotte.via.api';

function FormatTagIdAndNameTitle(entity: IAssetDTO | ISafetyDeviceVm | ISafetyDevicesByAssetVm) : string {
    const { name = '', tagId = '' } = entity;
    let finalName = tagId;

    if (name && name.trim().length > 0) {
        finalName += ` (${name})`;
    }

    return finalName;
}

function FormatAssetTitle(asset: IAssetDTO) {
    return FormatTagIdAndNameTitle(asset);
}

function FormatSafetyDeviceTitle(safetyDevice: ISafetyDeviceVm | ISafetyDevicesByAssetVm) {
    return FormatTagIdAndNameTitle(safetyDevice);
}

const TitleHelper = {
    FormatAssetTitle,
    FormatSafetyDeviceTitle
} as const;

export { TitleHelper };