import { Children, Component, ReactElement } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { File as CoreFile } from '../../core/File';
import Gallery from '../Gallery';
import ProtectedElement from '../ProtectedElement';
import { ButtonLabel } from './ButtonLabel';
import { EmptyList } from './EmptyList';
import { FileList } from './FileList';
import { FileUploadAdapter } from './FileUploadAdapter';
import { FileUploadInput } from './FileUploadInput';
import { ListTitle } from './ListTitle';
import { Title } from './Title';

type ChildrenElement = ReactElement<Title | ButtonLabel | ListTitle | EmptyList | Gallery>;

type FileUploadProps = WithTranslation & {
    children?: Array<ChildrenElement> | ChildrenElement;
    uploadAdapter: FileUploadAdapter;
    files?: Array<CoreFile>;
    showFileList?: boolean;
    onClick?: (file: CoreFile) => boolean;
    permissionsToView?: Array<string>;
    permissionsToEdit?: Array<string>;
    permissionsToDelete?: Array<string>;
}


class FileUpload extends Component<FileUploadProps> {
    render() {
        const {
            t,
            children,
            files,
            uploadAdapter,
            onClick,
            showFileList = true,
            permissionsToView = [],
            permissionsToEdit = [],
            permissionsToDelete = []
        } = this.props;
        const childrenArray = Children.toArray(children) as Array<ChildrenElement>;

        let titleChildren: Array<ReactElement<Title>> | ReactElement<Title> = childrenArray.filter((child) => child.type === Title);
        if (!titleChildren || titleChildren.length !== 1) {
            titleChildren = <Title>{t('components.FileUpload.Add Files')}</Title>;
        }

        let buttonLabelChildren: Array<ReactElement<ButtonLabel>> | ReactElement<ButtonLabel> = childrenArray.filter((child) => child.type === ButtonLabel);
        if (!buttonLabelChildren || buttonLabelChildren.length !== 1) {
            buttonLabelChildren = <ButtonLabel>{t('components.FileUpload.Add to files')}</ButtonLabel>;
        }

        let listTitleChildren: Array<ReactElement<ListTitle>> | ReactElement<ListTitle> = childrenArray.filter((child) => child.type === ListTitle);
        if (!listTitleChildren || listTitleChildren.length !== 1) {
            listTitleChildren = <ListTitle>{t('components.FileUpload.Files')}</ListTitle>;
        }

        let emptyListChildren: Array<ReactElement<EmptyList>> | ReactElement<EmptyList> = childrenArray.filter((child) => child.type === EmptyList);
        if (!emptyListChildren || emptyListChildren.length !== 1) {
            emptyListChildren = <EmptyList>{t('components.FileUpload.No files added')}</EmptyList>;
        }

        return (
            <div >
                <ProtectedElement somePermission={permissionsToEdit}>
                    {titleChildren}
                    <FileUploadInput uploadAdapter={uploadAdapter} >{buttonLabelChildren}</FileUploadInput>
                </ProtectedElement>

                <ProtectedElement somePermission={[...permissionsToView, ...permissionsToDelete]}>
                    {showFileList && <>
                        {listTitleChildren}
                        <FileList
                            files={files}
                            onDelete={uploadAdapter.deleteFile}
                            permissionsToDelete={permissionsToDelete}
                            onClick={onClick}
                        >{emptyListChildren}</FileList>
                    </>}
                </ProtectedElement>
            </div>
        );
    }
}

const FileUploadWithTranslation = withTranslation()(FileUpload);
export { FileUploadWithTranslation as FileUpload };
