import { useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Auth } from '../auth/Auth';

type UseViAHooks = {
    isOfflineDataChecked: boolean;
    isProcessingOfflineData: boolean;
    isMenuMinimized: boolean;
    backgroundColor: string;
    setMinimized: VoidFunction;
    setBackgroundColor: (color: string) => void;
    checkOfflineData: VoidFunction;
    auth: Auth | undefined;
};

const syncOfflineData = () => navigator.serviceWorker?.controller?.postMessage({ type: 'sync-offline-data'});

const checkOfflineData = () => navigator.serviceWorker?.controller?.postMessage({ type: 'check-offline-data'});

const useViAHooks = () : UseViAHooks => {
    const [ isOfflineDataChecked, setIsOfflineDataChecked ] = useState<boolean>(false);
    const [ isProcessingOfflineData, setIsProcessingOfflineData ] = useState<boolean>(false);
    const [ isMenuMinimized, setIsMenuMinimized ] = useState<boolean>(true);
    const [ backgroundColor, setBackgroundColor ] = useState<string>('white');
    const [ accessToken, setAccessToken ] = useState<string | undefined>();
    const [ auth, setAuth ] = useState<Auth | undefined>();

    const { isLoading, isAuthenticated, getAccessTokenSilently, user } = useAuth0();

    useEffect(() => {
        if (isLoading || !isAuthenticated) {
            return;
        }

        getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                scope: 'openid profile email access:all',
            },
        }).then(setAccessToken);

    }, [ getAccessTokenSilently, isAuthenticated, isLoading ]);

    useEffect(() => {
        if(!accessToken) {
            return undefined;
        }

        const a = new Auth(accessToken, user);
        a.startAsync().then(() => setAuth(a));

    }, [ accessToken ]);

    useEffect(() => {
        if (navigator.serviceWorker && navigator.serviceWorker.controller) {
            navigator.serviceWorker.addEventListener('message', serviceWorkerEventListener);

            checkOfflineData();

            // workaround with SW that is not updated
            setTimeout(checkOfflineSync, 5000);
        } else {
            console.log('no service worker');
            setIsProcessingOfflineData(false);
            setIsOfflineDataChecked(true);
        }

        return () => {
            if (navigator.serviceWorker && navigator.serviceWorker.controller) {
                navigator.serviceWorker.removeEventListener('message', serviceWorkerEventListener);
            }
        };
    }, []);

    useEffect(() => {
        if (navigator.serviceWorker && navigator.serviceWorker.controller && isProcessingOfflineData) {
            syncOfflineData();
        }
    }, [ isProcessingOfflineData ]);

    const serviceWorkerEventListener = (req: MessageEvent) => {
        const { data } = req;
        const { type } = data;

        if (!type) {
            return;
        }

        if (type === 'check-offline-data') {
            const { hasEntries = false, finished = true } = data;
            setIsProcessingOfflineData(hasEntries);
            setIsOfflineDataChecked(finished);
        }
    };

    const setMinimized = () => setIsMenuMinimized((value) => !value);

    const checkOfflineSync = () => {
        if (navigator.serviceWorker && navigator.serviceWorker.controller && !isProcessingOfflineData && !isOfflineDataChecked) {
            console.warn('It was not possible to check offline data sync');
            setIsOfflineDataChecked(true);
        }
    };

    return {
        isOfflineDataChecked,
        isProcessingOfflineData,
        isMenuMinimized,
        backgroundColor,
        setBackgroundColor,
        setMinimized,
        checkOfflineData,
        auth,
    };
};

export { useViAHooks };
