import { ReactNode } from 'react';
import { UseAuthorizationOptions, useAuthorization } from '../../hooks/useAuthorization';

interface ProtectedElementProps extends UseAuthorizationOptions {
    children: ReactNode;
}

function ProtectedElement({ children, permissions, somePermission, scopes }: ProtectedElementProps)  {
    const { isAuthorized } = useAuthorization({
        permissions,
        somePermission,
        scopes,
    });

    return isAuthorized() ? children : null;
}

export { ProtectedElement };

