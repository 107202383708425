import { useTranslation } from 'react-i18next';
import { ClientByMonikerVm, ProductionLineDto, ProductionLineVm, ProductionLinesVm } from '../../api/vincotte.via.api';
import { Primary as PrimaryButton } from '../../components/NavButtons/Primary';
import ProtectedElement from '../ProtectedElement';
import { ProductionUnit } from './ProductionUnit';

interface ProductionUnitsProps {
    productionUnits?: Array<ProductionLinesVm | ProductionLineDto | ProductionLineVm>;
    company?: ClientByMonikerVm;
    onDeleteSuccess?: () => void;
}

const ProductionUnits = (props: Readonly<ProductionUnitsProps>) => {
    const { t } = useTranslation();
    const { company, productionUnits, onDeleteSuccess } = props;

    const newProductionUnitLink = company
        ? <PrimaryButton to={`/production-unit/add/${company.moniker}`} >{t('components.ProductionUnits.New Production Unit')}</PrimaryButton>
        : null;

    const render = productionUnits && productionUnits.length > 0
        ? productionUnits.map((productionUnit, k) => (
            <ProductionUnit t={t} key={k} company={company} productionUnit={productionUnit} onDeleteSuccess={onDeleteSuccess} />
        ))
        : (<div>{t('components.ProductionUnits.No production units found')}</div>);

    return (
        <>
            <div className='row no-gutters pt-5 pb-3' >
                <div className='col-6 pt-2' >
                    <h2>{t('components.ProductionUnits.Production Units')}</h2>
                </div>

                <ProtectedElement permissions={['CanAddProductionLine']} >
                    <div className='col-6 text-right pr-3' >{newProductionUnitLink}</div>
                </ProtectedElement>
            </div>

            <div className='row no-gutters' >{render}</div>
        </>
    );
};

export { ProductionUnits };
