import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const PageNotFound = () => {
    const { t} = useTranslation();
    const location = useLocation();

    return (
        <div>
            <h3>{t('pages.PageNotFound.No match for')} <code>{location.pathname}</code></h3>
        </div>
    );
};

export { PageNotFound };
