import { CSSProperties, forwardRef, InputHTMLAttributes, ReactNode, Ref, SVGAttributes } from 'react';
import { CheckSquare, Square } from 'react-feather';
import { ID } from '../../utils';

type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
    label?: ReactNode;
};

const labelStyle: CSSProperties = {
    display: 'inline-block',
    marginBottom: 0,
    position: 'relative',
};

const spanStyle: CSSProperties = {
    display: 'table-cell',
    verticalAlign: 'middle',
};

const iconStyle: CSSProperties = {
    display: 'block',
    marginRight: '10px',
};

const iconStyleDisabled: CSSProperties = {
    ...iconStyle,
    opacity: .5,
};

const Checkbox = forwardRef((props: Readonly<CheckboxProps>, ref: Ref<HTMLInputElement>) => {
    const {
        id = ID(),
        label,
        checked = false,
        defaultChecked = false,
        className,
        title,
        disabled,
        required = false,
        ...rest
    } = props;

    const isChecked = checked || defaultChecked;

    const svgAttributes: SVGAttributes<SVGElement> = {
        height: 32,
        width: 32,
        style: disabled ? iconStyleDisabled : iconStyle,
    };

    const r = required ? '*' : '';

    const checkbox = isChecked ? <CheckSquare {...svgAttributes}  /> : <Square {...svgAttributes}/>;

    return (
        <>
            <input id={id} className='d-none' checked={checked} required={required} type="checkbox" title={title} {...rest} ref={ref} disabled={disabled}  />

            <label htmlFor={id} title={title} style={labelStyle} className={className} >
                <span style={spanStyle} >{checkbox}</span>
                <span style={spanStyle} >{label}{r}</span>
            </label>
        </>
    );
});

Checkbox.displayName = 'Checkbox';

export { Checkbox };
