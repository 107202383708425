import { Children, cloneElement, isValidElement, PureComponent, ReactElement } from 'react';
import { Button, ButtonProps, CancelButton, CancelButtonProps, ConfirmButton, ConfirmButtonProps, Custom, ErrorMessage, Header, Message } from '.';
import PopUp from '../PopUp';
import { PopUpProps } from '../PopUp/PopUp';

type ChildrenElement = ReactElement<Header | Message | Custom | Button | CancelButton | ConfirmButton>;

type ConfirmationMessageProps = PopUpProps & {
    children: Array<ChildrenElement> | ChildrenElement;
    isLoading?: boolean;
}

export class ConfirmationMessage extends PureComponent<ConfirmationMessageProps> {
    render() {
        const { children, isLoading = false, ...rest } = this.props;

        const childrenArray = Children.toArray(children) as Array<ChildrenElement>;

        const header = childrenArray.filter((child) => child.type === Header);
        const message = childrenArray.filter((child) => child.type === Message);
        const errorMessage = childrenArray.filter((child) => child.type === ErrorMessage);
        const custom = childrenArray.filter((child) => child.type === Custom);

        const button = childrenArray.filter((child) => child.type === Button).map((child) => {
            if (isValidElement(child)) {
                return cloneElement<ButtonProps>((child as ReactElement<ButtonProps>), { disabled: isLoading }) as ChildrenElement;
            }
        });

        const cancelButton = childrenArray.filter((child) => child.type === CancelButton).map((child) => {
            if (isValidElement(child)) {
                return cloneElement<CancelButtonProps>((child as ReactElement<CancelButtonProps>), { disabled: isLoading }) as ChildrenElement;
            }
        });

        const confirmButton = childrenArray.filter((child) => child.type === ConfirmButton).map((child) => {
            if (isValidElement(child)) {
                return cloneElement<ConfirmButtonProps>((child as ReactElement<ConfirmButtonProps>), { disabled: isLoading }) as ChildrenElement;
            }
        });

        return (
            <PopUp {...rest} >
                {header}
                {errorMessage}
                {message}

                {custom}

                <div className='mt-3' style={{float:'right'}}>
                    {button}

                    {cancelButton}

                    {confirmButton}
                </div>
            </PopUp>
        );
    }
}