import { Component, HTMLAttributes } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IFileDTO, IFileDto3, IFileDto4, IFilesRelatedToInspectionVm } from '../../api/vincotte.via.api';
import { File as CoreFile } from '../../core/File';
import { FileProxyHelper } from '../../utils';

type FileDownloadLinkProps = WithTranslation &
    HTMLAttributes<HTMLAnchorElement> & {
    file: CoreFile | IFileDto3 | IFileDto4 | IFileDTO | IFilesRelatedToInspectionVm;
}

class FileDownloadLink extends Component<FileDownloadLinkProps> {
    render() {
        const { t, file, title, tReady, ...rest } = this.props;

        const normalizedTitle = title ? title : t('components.FileDownloadLink.Download file \'{name}\'', { name: file.fileName });

        return (
            <a
                href={FileProxyHelper.createMediaLink(file)}
                target='_blank'
                rel='noreferrer'
                title={normalizedTitle}
                {...rest}
            />
        );
    }
}

const FileDownloadLinkWithTranslation = withTranslation()(FileDownloadLink);
export { FileDownloadLinkWithTranslation as FileDownloadLink };
