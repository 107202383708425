import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ImgIconBase } from './ImgIconBase';

class FileBack extends Component {
    render() {
        return (
            <ImgIconBase src='/images/icons/file-back.svg' {...this.props} />
        );
    }
}

FileBack.propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
};

export { FileBack };