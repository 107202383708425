import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { LanguagesSelector } from './LanguagesSelector';
import { useAuth0 } from '@auth0/auth0-react';
import * as Icons from '../Icons';
import { MouseEvent, memo, useMemo } from 'react';

interface SettingsProps {
    isMenuMinimized?: boolean;
}

function Settings({ isMenuMinimized = false } : SettingsProps) {
    const location = useLocation();
    const { t } = useTranslation();
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

    const logTitle = isAuthenticated ? t('components.NavigationBar.Log out') : t('components.NavigationBar.Log in');
    const logIcon = isAuthenticated ? <Icons.LogOut title={logTitle} /> : <Icons.LogIn title={logTitle} />;

    const logOnClick = useMemo(() => (event: MouseEvent<HTMLParagraphElement>) => {
        if (isAuthenticated) {
            logout();
            return;
        }

        loginWithRedirect({
            appState: {
                returnTo: location.pathname,
            },
        });
    }, [ isAuthenticated ]) ;

    return  <div className='popUp-Menu-wrapper '>
        <div className='popUp-Menu shadow'>
            <NavLink className="optionItem" to='/profile' title={t('components.NavigationBar.Profile')} >{t('components.NavigationBar.Account')}</NavLink>

            <LanguagesSelector isMenuMinimized={isMenuMinimized} />

            <div style={{width:'80%',margin:'auto', height:'1px', backgroundColor:'rgb(0, 91, 195)'}}></div>
            <p className="optionItem" style={{paddingTop:'.5em'}} onClick={logOnClick} title={logTitle}>
                {logIcon}
                <span style={{position: 'relative',top: '.2em'}}>
                    {isAuthenticated ? 'Log Out' : 'Log In'}
                </span>
            </p>
        </div>
    </div>;
}

const SettingsWithMemo = memo(Settings);

export { SettingsWithMemo as Settings };