import { Component, ReactNode } from 'react';

type TitleProps = {
    children?: ReactNode;
}

export class Title extends Component<TitleProps> {
    render() {
        return (<h2>{this.props.children}</h2>);
    }
}
