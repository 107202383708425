import { FunctionComponent, createElement, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UseAuthorizationOptions, useAuthorization } from '../../hooks/useAuthorization';
import { BackgroundColor, PageBackgroundColorContext } from '../PageBackgroundColor';

interface MenuMinimizedProps<T = any> {
    component: FunctionComponent<T>;
    isMenuMinimized?: boolean;
}

interface ProtectedRouteProps<T = any> extends UseAuthorizationOptions, MenuMinimizedProps<T> {
    backgroundColor?: string;
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
    const { t } = useTranslation();
    const { setBackgroundColor } = useContext(PageBackgroundColorContext);

    const {
        permissions,
        somePermission,
        scopes,
        backgroundColor = BackgroundColor.DefaultPage } = props;

    const { isAuthenticated, isAuthorized, login } = useAuthorization({
        permissions,
        somePermission,
        scopes
    });

    useEffect(() => {
        setBackgroundColor(backgroundColor);
    }, [backgroundColor]);


    const {
        component: WrappedComponent,
        isMenuMinimized = false,
        ...rest
    } = props;

    const propsToRender = {
        isMenuMinimized,
        ...rest
    } as MenuMinimizedProps;


    if (!isAuthenticated()) {
        login();
        return null;
    }

    if (!isAuthorized()) {
        return (<h1>{t('components.ProtectedRoute.Unauthorized')}</h1>);
    }

    if (!WrappedComponent) {
        throw new TypeError('Invalid component');
    }

    return createElement(WrappedComponent, propsToRender);
};

export { ProtectedRoute };
