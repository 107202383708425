import { CSSProperties, MouseEvent, PureComponent } from 'react';
import { WithTranslation } from 'react-i18next';
import { ClientByMonikerVm, ClientsVm } from '../../api/vincotte.via.api';
import CompanyDeleteCard from '../CompanyDeleteCard';
import ProtectedElement from '../ProtectedElement';
import ThreeDotsMenu, { Entry, Separator } from '../ThreeDotsMenu';
import { ThreeDotsMenuPosition } from '../ThreeDotsMenu/ThreeDotsMenu';


type CompanyMenuProps = Pick<WithTranslation, 't'> & {
    company: ClientsVm | ClientByMonikerVm;
    position?: ThreeDotsMenuPosition;
    style?: CSSProperties;
    onDeleteSuccess?: () => void;
}

type CompanyMenuState = {
    showDeleteMenu: boolean;
};

export class CompanyMenu extends PureComponent<CompanyMenuProps,CompanyMenuState> {
    private readonly threeDotsMenuStyle : CSSProperties = {
        top: '1em',
        right: '1em',
    };

    public readonly state: Readonly<CompanyMenuState> = {
        showDeleteMenu: false,
    };

    showDeleteMenu = (event: MouseEvent<HTMLAnchorElement>) => {
        if (event.cancelable) {
            event.stopPropagation();
            event.preventDefault();
        }

        this.setState({ showDeleteMenu: true });
    }

    hideDeleteMenu = () => this.setState({ showDeleteMenu: false });

    onDeleteProductionUnit = () => {
        this.hideDeleteMenu();

        if (this.props.onDeleteSuccess) {
            this.props.onDeleteSuccess();
        }
    }

    render() {
        const { t, position, company, style = this.threeDotsMenuStyle  } = this.props;
        const { showDeleteMenu } = this.state;

        return(
            <>
                <ProtectedElement somePermission={['CanEditClient', 'CanDeleteClient']} >
                    <ThreeDotsMenu background="transparent" style={style} position={position} >
                        <ProtectedElement permissions={['CanEditClient']} >
                            <Entry to={`/company/edit/${company.moniker}`} >{t('components.Company.Edit')}</Entry>
                        </ProtectedElement>

                        <Separator />

                        <ProtectedElement permissions={['CanDeleteClient']} >
                            <Entry title="delete" onClick={this.showDeleteMenu} >{t('components.Company.Delete')}</Entry>
                        </ProtectedElement>
                    </ThreeDotsMenu>
                </ProtectedElement>

                {
                    showDeleteMenu &&
                    <CompanyDeleteCard
                        companies={[company]}
                        onDelete={this.onDeleteProductionUnit}
                        onClose={this.hideDeleteMenu} />
                }
            </>
        );
    }
}