import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withForwardedRef } from '../../HOC/withForwardedRef';
import { UploadProgress } from './UploadProgress';
import { FileUploadProgressProvider } from './FileUploadProgressContext';
import { Progress } from './Progress';
import { FileUploadProgressController } from './FileUploadProgressController';
import { Icon as IconButton } from '../Buttons/Icon';
import * as Icons from '../Icons';
import { withTranslation } from 'react-i18next';
import { FailedProgress } from './FailedProgress';

class FileUploadProgress extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            collapsed: false,
            files: { },
            controller: null,
        };
    }

    componentDidMount() {
        this.uploadProgress();
    }

    uploadProgress = () => {
        const controller = new FileUploadProgressController((state) => {
            this.setState(state);
        });

        this.setState({ controller });
    }

    componentDidUpdate(prevProps, prevState) {
        const { visible } = this.state;

        const shouldBeVisible = Object.keys(prevState.files).length > 0;

        if (shouldBeVisible !== visible) {
            this.setState({ visible: shouldBeVisible });
        }
    }

    toggle = () => {
        return this.setState((state) => ({ collapsed: !state.collapsed }));
    }

    render() {
        const { visible, collapsed, files, controller } = this.state;
        if (!controller) {
            return null;
        }

        const { t, children, forwardedRef } = this.props;

        const iconLabel = collapsed ? t('components.FileUploadProgress.Show') : t('components.FileUploadProgress.Hide');
        const icon = collapsed ? <Icons.ArrowDown title={iconLabel} ></Icons.ArrowDown> : <Icons.ArrowUp title={iconLabel} ></Icons.ArrowUp>;

        const filesArr = Object.entries(files);
        const progressFiles = filesArr.filter((file) => !file[1].failed);
        const failedFiles = filesArr.filter((file) => file[1].failed);

        return (
            <FileUploadProgressProvider controller={controller} >
                {
                    visible
                    && <UploadProgress ref={forwardedRef} >
                        {
                            !collapsed
                            && <>
                                {failedFiles.length > 0
                                && <FailedProgress onClose={() => this.close(failedFiles)} >
                                    <ul className='list-unstyled' >{failedFiles.map(([ index, file ]) => (
                                        <li key={index}>{file.fileName}</li>
                                    ))}</ul>
                                </FailedProgress>
                                }

                                {progressFiles.map(
                                    (data, key) => {
                                        const {completed, label, fileName, abortController } = data[1];
                                        return <Progress
                                            key={key}
                                            completed={completed}
                                            abortController={abortController}
                                        >{fileName} - {label}</Progress>;
                                    }
                                )}
                            </>
                        }

                        <div className='row'>
                            <div className='col text-center'>
                                <IconButton className='bg-white shadow' onClick={this.toggle} title={iconLabel} >{icon}</IconButton>
                            </div>
                        </div>
                    </UploadProgress>
                }

                {children}
            </FileUploadProgressProvider>

        );
    }

    close = (filesToRemove) => {
        this.setState(({ files }) => {
            filesToRemove.forEach(([ index ]) => {
                if (files[index] !== undefined && files[index] !== null) {
                    delete files[index];
                }
            });

            return { files };
        });
    }
}

FileUploadProgress.propTypes = {
    t: PropTypes.func.isRequired,
    children: PropTypes.any,
    forwardedRef: PropTypes.object,
};

const FileUploadProgressWithForwardedRef = withForwardedRef()(FileUploadProgress);
const FileUploadProgressWithTranslation = withTranslation()(FileUploadProgressWithForwardedRef);
export { FileUploadProgressWithTranslation as FileUploadProgress };