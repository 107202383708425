import { Ref, SelectHTMLAttributes, forwardRef } from 'react';

type DropdownUnitRow = {
    value: number | string;
    label: string;
}

type DropdownUnitProps = SelectHTMLAttributes<HTMLSelectElement> & {
    rows: Array<DropdownUnitRow>;
};

const DropdownUnit = forwardRef((props: Readonly<DropdownUnitProps>, ref: Ref<HTMLSelectElement>) => {
    const { placeholder, rows, disabled = false, className = '', style = {} , ...rest} = props;

    const s = {
        height:'2.5em',
        ...style,
    };

    const c = `${disabled ? 'vincotte-input-disabled vincotte-input-small vincotte-input' : 'vincotte-input vincotte-input-small shadow'} ${className}`;

    return (
        <select
            style={s}
            className={c}
            disabled={disabled}
            {...rest}
        >
            <option value="" >{placeholder ?? ''}</option>
            {rows.map(({ value, label }) => <option key={value} value={value}>{label}</option>)}
        </select>
    );
});

DropdownUnit.displayName = 'DropdownUnit';

export { DropdownUnit };