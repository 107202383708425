import { ComponentType, createElement, memo } from 'react';

export type WithPureComponentProps = Record<string, any>;

export function withPureComponent() {
    return function <TProps extends WithPureComponentProps = any>(
        WrappedComponent: ComponentType<TProps>
    ) {
        const handler = (props: TProps) => {
            return createElement<TProps>(WrappedComponent, props);
        };

        const name = WrappedComponent.displayName || WrappedComponent.name || 'Component';
        handler.displayName = `withPureComponent(${name})`;
        handler.WrappedComponent = WrappedComponent;

        return memo(handler, () => true);
    };
}