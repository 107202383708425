import { PureComponent } from 'react';
import { ImgIconBase, ImgIconDetailedHTMLProps } from './ImgIconBase';

type StatusProps = ImgIconDetailedHTMLProps & {
    status?: 'done' | 'todo' | 'partial' | 'not-defined';
};

export class Status extends PureComponent<StatusProps> {
    render() {
        const { status = 'not-checked', ...rest } = this.props;

        return (
            <ImgIconBase src={`/images/icons/status-${status}.svg`} {...rest} />
        );
    }
}