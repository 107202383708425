import { User } from '@auth0/auth0-react';
import { PermissionService } from '../services/PermissionService';

const REDIRECT_ON_LOGIN = 'redirect_on_login';

export class Auth {

    #_accessToken: string;
    #_permissions: Array<string> = new Array<string>();
    #_user: User | undefined;

    constructor(accessToken: string, user?: User) {
        this.#_accessToken = accessToken;
        this.#_user = user;
    }

    get accesToken() : string {
        return this.#_accessToken;
    }

    get user() : User | undefined {
        return this.#_user;
    }

    startAsync = () => {
        const permissionsService = new PermissionService(process.env.REACT_APP_API_URL ?? '', this);

        // eslint-disable-next-line no-unused-vars
        return this.setAccessCookieAsync().then(() => permissionsService.getPermissions().then((permissions) => {
            this.#_permissions = permissions;
            return;
        }));
    }

    setAccessCookieAsync = () => {
        const requestInit = {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${this.accesToken}`,
            },
        };

        return fetch('/access-token', requestInit);
    }

    clearAccessCookie = () => {
        const requestInit = {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${this.accesToken}`,
            },
        };

        return fetch('/access-token', requestInit);
    }

    userHasPermissions = (permissions: Array<string>) => {
        return permissions.every((permission) => this.#_permissions.includes(permission));
    }

    userHasSomePermission = (permissions: Array<string>) => {
        return permissions.some((permission) => this.#_permissions.includes(permission));
    }
}