import { Children, ReactNode, memo } from 'react';
import './ProgressBar.css';

type TextColor = 'white' | 'black';

type Position = 'stacked' | 'sided';

interface ProgressBarProps {
    children?: ReactNode;
    completed: number;
    textColor?: TextColor;
    className?: string;
    isAnimated?: boolean;
    position?: Position;
}

const ProgressBar = memo((props: Readonly<ProgressBarProps>) => {
    const { children, completed, className = '', textColor, position = 'stacked', isAnimated = false } = props;

    const propsToRender = {
        style: {
            width: `${completed}%`,
        },
        role: 'progressbar',
        'aria-valuenow': completed,
        'aria-valuemin': 0,
        'aria-valuemax': 100
    };

    const color = textColor ? `text-vincotte-${textColor}` : '';

    return (
        <div className={`row no-gutters ${className}`} data-testid='progress-bar-wrapper'>
            <div className='col'>
                {Children.count(children) > 0 && <p className={`text-center m-0 ${color} progress-paragraph-${position}`} data-testid='progress-bar-children' >{children}</p>}
                <div className={`progress progress-background progress-background-${position}`} >
                    <div className={`progress-bar ${isAnimated ? 'progress-bar-animated progress-bar-striped ' : ''}`} {...propsToRender} ></div>
                </div>
            </div>
        </div>
    );
});

ProgressBar.displayName = 'ProgressBar';

export { ProgressBar };
