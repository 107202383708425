import { Component, HTMLAttributes } from 'react';

type CustomProps = HTMLAttributes<HTMLDivElement>;

export class Custom extends Component<CustomProps> {
    render() {
        return (
            <div {...this.props} />
        );
    }
}