import { Component, HTMLAttributes, PureComponent } from 'react';

type HeaderProps = HTMLAttributes<HTMLHeadingElement>;

export class Header extends Component<HeaderProps> {
    render() {
        return (
            <h2 {...this.props} />
        );
    }
}