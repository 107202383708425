import { ColorHelper } from './ColorHelper';
import { HUEColor } from './HUEColor';

export class HeatmapColor {

    #_hueBelowMinimumAcceptable : number = HUEColor.Red;
    #_hueMinimumAcceptable : number = HUEColor.Orange;
    #_hueMaximumAcceptable : number = HUEColor.Green;
    #_hueWithoutMinimumAcceptableValue : number = HUEColor.Purple;

    private readonly hueMinimum: number = 0;
    private readonly hueMaximum: number = 360;
    private readonly saturation: number = 100;
    private readonly lightness: number = 50;

    private readonly nominal: number;
    private readonly minimum: number | undefined;
    private readonly maximum: number;
    private readonly rangePercentage: number | undefined;

    constructor(nominal: number, minimumAcceptableValue: number | undefined = undefined) {
        this.nominal = nominal;
        this.minimum = minimumAcceptableValue;
        this.maximum = this.nominal;
        if (this.minimum) {
            const range = this.maximum - this.minimum;
            this.rangePercentage = 100 / range;
        }
    }

    get hueBelowMinimumAcceptable() : number {
        return this.#_hueBelowMinimumAcceptable;
    }

    set hueBelowMinimumAcceptable(hueBelowMinimumAcceptable: number) {
        this.ensureHUEValue(hueBelowMinimumAcceptable);

        this.#_hueBelowMinimumAcceptable = hueBelowMinimumAcceptable;
    }

    get hueMinimumAcceptable() : number {
        return this.#_hueMinimumAcceptable;
    }

    set hueMinimumAcceptable(hueMinimumAcceptable: number) {
        this.ensureHUEValue(hueMinimumAcceptable);

        this.#_hueMinimumAcceptable = hueMinimumAcceptable;
    }

    get hueMaximumAcceptable() : number {
        return this.#_hueMaximumAcceptable;
    }

    set hueMaximumAcceptable(hueMaximumAcceptable: number) {
        this.ensureHUEValue(hueMaximumAcceptable);

        this.#_hueMaximumAcceptable = hueMaximumAcceptable;
    }

    get hueWithoutMinimumAcceptableValue() : number {
        return this.#_hueWithoutMinimumAcceptableValue;
    }

    set hueWithoutMinimumAcceptableValue(hueWithoutMinimumAcceptableValue: number) {
        this.ensureHUEValue(hueWithoutMinimumAcceptableValue);

        this.#_hueWithoutMinimumAcceptableValue = hueWithoutMinimumAcceptableValue;
    }

    private ensureHUEValue(value: number) {
        if (value < 0 || value > 360) {
            throw new TypeError(`Value must be between 0 and 360. Value given: ${value}`);
        }
    }

    getColor(value: number) : string {
        if (!this.minimum || !this.rangePercentage) {
            return ColorHelper.hslToHex(this.hueWithoutMinimumAcceptableValue, this.saturation, this.lightness);
        }

        if (value < this.minimum) {
            return ColorHelper.hslToHex(this.hueBelowMinimumAcceptable, this.saturation, this.lightness);
        }

        if (value >= this.maximum) {
            return ColorHelper.hslToHex(this.hueMaximumAcceptable, this.saturation, this.lightness);
        }

        const valueOnRange = value - this.minimum;
        const percentage = valueOnRange * this.rangePercentage;

        const hueAbsoluteValuePercent = (this.hueMaximumAcceptable - this.hueMinimumAcceptable) / 100;

        let hue = this.hueMinimumAcceptable + (hueAbsoluteValuePercent * percentage);
        hue = Math.round(hue);
        hue = hue < this.hueMinimumAcceptable ? this.hueMinimumAcceptable : hue;
        hue = hue > this.hueMaximum ? this.hueMaximum : hue;

        return ColorHelper.hslToHex(hue, this.saturation, this.lightness);
    }
}

