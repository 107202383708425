import { PureComponent } from 'react';
import { ImgIconBase, ImgIconDetailedHTMLProps } from './ImgIconBase';

type MeasurementProps = ImgIconDetailedHTMLProps;

export class Measurement extends PureComponent<MeasurementProps> {

    render() {
        return (
            <ImgIconBase src='/images/icons/measurement.svg' {...this.props} />
        );
    }
}
