import { Component, createRef, CSSProperties, ReactNode, RefObject } from 'react';
import './ThreeDotsMenu.css';

export type ThreeDotsMenuPosition =  'left' | 'right' | 'bottomToRight' | 'bottomToLeft';

type ThreeDotsMenuProps = {
    children: ReactNode;
    background?: string;
    color?: string;
    style?: CSSProperties;
    position?: ThreeDotsMenuPosition;
}

type ThreeDotsMenuState = {
    isOpen: boolean;
}

export class ThreeDotsMenu extends Component<ThreeDotsMenuProps, ThreeDotsMenuState> {

    public readonly state: Readonly<ThreeDotsMenuState> = {
        isOpen: false,
    };

    #_ref: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, { passive: true });
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }

    handleClickOutside = (event: MouseEvent) => {
        if (!this.state.isOpen || !event.target) {
            return;
        }

        const node = event.target as Node;

        this.setState({ isOpen: false });
    }


    onOptionClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.cancelable) {
            event.preventDefault();
            event.stopPropagation();
        }

        this.setState({ isOpen: !this.state.isOpen });
    };

    private readonly svgStyle: CSSProperties = { marginLeft:'10px' };


    render() {
        const {
            children,
            background,
            color = '#272626',
            style,
            position = 'left',
        } = this.props;
        const { isOpen } = this.state;

        return (
            <>
                <div className="threeDotsMenu " style={style} onClick={this.onOptionClick}>
                    <svg width="35" height="35" style={this.svgStyle} viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="45" height="45" fill={background}/>
                        <circle cx="22" cy="9" r="4" fill={color} />
                        <circle cx="22" cy="22" r="4" fill={color} />
                        <circle cx="22" cy="35" r="4" fill={color} />
                    </svg>
                </div>

                {
                    isOpen &&
                    <div className={`optionsMenu shadow ${position}`} >{children}</div>
                }
            </>
        );
    }
}