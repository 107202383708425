import { Ref, forwardRef } from 'react';
import { Link, LinkProps, To } from 'react-router-dom';

interface EntryProps extends Omit<LinkProps, 'to'> {
    to?: To;
}

const Entry = forwardRef((props: EntryProps, ref: Ref<HTMLAnchorElement>) => {
    const { className, to = '#', style = {}, ...rest } = props;
    const c = `optionItem ${className}`;
    const s = {
        color:'black',
        ...style
    };

    return (
        <Link style={s} className={c} to={to} ref={ref} {...rest} />
    );
});

Entry.displayName = 'Entry';

export { Entry };
