import { PureComponent } from 'react';
import { ImgIconBase, ImgIconDetailedHTMLProps } from './ImgIconBase';

type PlayProps = ImgIconDetailedHTMLProps;

export class Play extends PureComponent<PlayProps> {
    render() {
        return (
            <ImgIconBase src='/images/icons/video-player/play.svg' {...this.props} />
        );
    }
}