import { Entity } from '../core/Entity';
import { ApiResponse } from './ApiResponse';

interface Endpoint {
    segments?: Array<any>
    queryString?: Record<string, any>
}

type ApiEntity = Entity | Record<any, any> | null;

export class AbstractApiService {
    constructor(url: URL | string) {
        this._url = new URL(url instanceof URL ? url.toString() : url);
    }

    private readonly _url;
    get url() : URL {
        return this._url;
    }

    endpoint(endpoint: Endpoint = {}) : URL {
        let s = '';

        const segments = endpoint.segments || [];
        const queryString = endpoint.queryString || {};

        const uri = new URL(this.url.toString());
        if (segments.length > 0) {
            s += segments.reduce((previousValue, currentValue) => `${previousValue}/${currentValue}`);
            uri.pathname += `${s}`;
        }

        if (queryString) {
            Object.keys(queryString).forEach((k) => (!queryString[k] && queryString[k] !== undefined) && delete queryString[k]);
            Object.keys(queryString).filter(k => Array.isArray(queryString[k])).forEach((k) => queryString[k].forEach((_: any) => uri.searchParams.append(k, _)));
            Object.keys(queryString).filter(k => !Array.isArray(queryString[k])).forEach((k) => uri.searchParams.append(k, queryString[k]));
        }

        return uri;
    }

    fetch(input: RequestInfo, requestInit: RequestInit | undefined = undefined) {
        const init = requestInit || {};

        return fetch(input, init)
            .then((response) => ApiResponse.createInstance(response))
            .catch((error) => ApiResponse.createErrorInstance(error.message));
    }

    get = (endpoint: Endpoint, abortSignal: AbortSignal | null= null) : Promise<any> => {
        return this.fetch(
            this.endpoint(endpoint).toString(),
            this.createRequestInit('GET', null, abortSignal)
        );
    }

    post = (endpoint: Endpoint, entity: ApiEntity, abortSignal: AbortSignal | null = null) : Promise<any> => {
        return this.fetch(
            this.endpoint(endpoint).toString(),
            this.createRequestInit('POST', entity, abortSignal)
        );
    }

    put = (endpoint: Endpoint, entity: ApiEntity, abortSignal: AbortSignal | null = null) : Promise<any> => {
        return this.fetch(
            this.endpoint(endpoint).toString(),
            this.createRequestInit('PUT', entity, abortSignal)
        );
    }

    patch = (endpoint: Endpoint, entity: ApiEntity, abortSignal: AbortSignal | null = null) : Promise<any> => {
        return this.fetch(
            this.endpoint(endpoint).toString(),
            this.createRequestInit('PATCH', entity, abortSignal)
        );
    }

    delete = (endpoint: Endpoint, abortSignal: AbortSignal | null = null) : Promise<any> => {
        return this.fetch(
            this.endpoint(endpoint).toString(),
            this.createRequestInit('DELETE', null, abortSignal)
        );
    }

    createRequestInit = (method: string, entity: ApiEntity, abortSignal: AbortSignal | null = null) : RequestInit | undefined => {
        if (!method) {
            throw new Error('Method parameter is missing');
        }

        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };

        if (entity && 'memoryId' in entity) {
            (headers as Record<string, any>)['x-mem-id'] = entity.memoryId;
        }

        const ret = { method, headers };

        if (abortSignal) {
            (ret as Record<string, any>).signal = abortSignal;
        }

        if (entity) {
            (ret as Record<string, any>).body = Entity.stringify(entity);
        }

        return ret;
    }
}