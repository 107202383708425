import { Component } from 'react';
import { Default, DefaultProps } from '../Buttons/Default';

export type ButtonProps = DefaultProps;

class Button extends Component<ButtonProps> {
    render() {
        const { children, ...rest } = this.props;

        rest.className = rest.className ? `${rest.className} btn-vincotte-padding` : 'btn-vincotte-padding';

        return (<Default {...rest} >{children}</Default>);
    }
}

export { Button };