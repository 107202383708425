export {};

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/sw.bundle.js').catch((registrationError) => {
            console.log('SW registration failed: ', registrationError);
        });

        navigator.serviceWorker.ready.then((registration) => {
            const syncRegistration = [registration.sync.register('sync-data')];

            // // feature detection
            // if ('periodicSync' in registration) {
            //     // Background Periodic Sync functionality
            //     syncRegistration.push(registration.periodicSync.register('sync-data', { minInterval: 10000 }));
            // }

            return Promise.all(syncRegistration);
        }).catch(function() {
            // system was unable to register for a sync,
            // this could be an OS-level restriction
            console.log('sync registration failed');
        });
    });

    // window.addEventListener('load', () => {
    //     navigator.serviceWorker.register('/backgrounSync.sw.bundle.js').then((registration) => {
    //         console.log('SW registration successful. Scope: ', registration.scope);
    //     }).catch((registrationError) => {
    //         console.log('SW registration failed: ', registrationError);
    //     });
    // });

    // navigator.serviceWorker.addEventListener('message', (event) => {
    //     console.log('message', event.data.message);
    // });
}
