export class DateTime {
    // Allowed formats
    // YYYY-MM-DD
    // YYYY-MM-DDThh:mm:ss
    // YYYY-MM-DDThh:mm:ss.sTZD
    static get datePattern() : RegExp {
        return /^(\d{4})-(\d{2})-(\d{2})(T(\d{2}):(\d{2}):(\d{2})(.(\d{1,3})(Z|((\+|-)(\d{2}:\d{2})))?)?)?$/;
    }

    static isoStringToDateString(isoString: string) : string {
        const split = isoString.split('T');
        const date = split.at(0);

        return date ?  date : isoString;
    }

    static dateToString(date: Date) : string {
        if (isNaN(date.getTime())) {
            return '';
        }

        return DateTime.isoStringToDateString(
            new Date(date.getTime() - (date.getTimezoneOffset() * 60000 )).toISOString()
        );
    }

    static stringToDate(dateString: string) : Date {
        if (!DateTime.datePattern.test(dateString)) {// && !DateTime.iso8601.test(dateString)) {
            throw new Error('Parameter is not a valid format (YYYY-MM-DD or YYYY-MM-DD\\Thh:mm:ss)!');
        }

        return new Date(dateString);
    }

    static diffDays(start: Date, end: Date) {
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        return Math.round(Math.abs((end.getTime() - start.getTime()) / oneDay));
    }

    static dateToInput(date: Date | string) : string {
        let d = date;
        if (!(d instanceof Date)) {
            if (!DateTime.datePattern.test(d)) {// && !DateTime.iso8601.test(dateString)) {
                return '';
            }

            d = this.stringToDate(d);
        }

        return this.dateToString(d);
    }

    static dateTimeToInput(date: Date | string) : string {
        let d = date;
        if (!(d instanceof Date)) {
            if (!DateTime.datePattern.test(d)) {// && !DateTime.iso8601.test(dateString)) {
                return '';
            }

            d = this.stringToDate(d);
        }

        return d.toLocaleString();
    }

    static inputField(date: Date | string) : string {
        if (!date || date === '') {
            return '';
        }

        if (date instanceof Date) {
            return DateTime.dateToString(date);
        }

        if (date.includes('T')) {
            return DateTime.dateToString(new Date(date));
        }

        return date;
    }

    static toPrintDate(date?: Date | string) : string {
        if (!date) {
            return '';
        }

        if (date instanceof Date) {
            return date.toLocaleDateString();
        }

        return date;
    }
}