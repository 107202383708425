import { Observable } from '@babylonjs/core/Misc/observable';
import { ApiResponse } from '../services/ApiResponse';
import { SharedAccessSignatureBlob } from './SharedAccessSignatureBlob';
import { SharedAccessSignatureChunk } from './SharedAccessSignatureChunk';
import { SharedAccessSignatureUploader } from './SharedAccessSignatureUploader';

export class SharedAccessSignature {

    // 10 MB
    public static readonly MAXIMUN_SIZE_UNIQUE_UPLOAD: number = 10 * 1024 * 1024;

    // 8 MB
    public static readonly MAXIMUM_BYTES_PER_CHUNK: number = 8 * 1024 * 1024;

    // 4 MB
    public static readonly MINIMUM_BYTES_PER_CHUNK: number = 4 * 1024 * 1024;

    constructor(url: string, blob: Blob) {
        this._url = new URL(url);
        this._blob = blob;
        this._sasBlob = new SharedAccessSignatureBlob(this);
    }

    private _url: URL;
    private _blob: Blob;
    private _sasBlob: SharedAccessSignatureBlob;

    get url() : URL {
        return this._url;
    }

    get file() : File {
        return this.blob as File;
    }

    get blob() : Blob {
        return this._blob;
    }

    upload = (abortSignal?: AbortSignal) : Promise<boolean> => {
        const uploader = new SharedAccessSignatureUploader();
        return uploader.upload(this._sasBlob, abortSignal).then((result: ApiResponse | boolean) => {
            this.OnCompleted.notifyObservers({ blob: this._sasBlob });
            return true;
        }).catch((error: Error) => {
            if (error.name !== 'AbortError') {
                this.OnFailure.notifyObservers({ error, blob: this._sasBlob });
            }

            if (error.name === 'AbortError') {
                this.OnCancelled.notifyObservers({ abortSignal, error, blob: this._sasBlob });
            }

            throw error;
        });
    }

    public readonly OnCompleted: Observable<ISharedAccessSignatureOnCompleted> = new Observable<ISharedAccessSignatureOnCompleted>();
    public readonly OnCancelled: Observable<ISharedAccessSignatureOnCancelled> = new Observable<ISharedAccessSignatureOnCancelled>();
    public readonly OnFailure: Observable<ISharedAccessSignatureOnFailure> = new Observable<ISharedAccessSignatureOnFailure>();
    public readonly OnProgress: Observable<ISharedAccessSignatureOnProgress> = new Observable<ISharedAccessSignatureOnProgress>();
    public readonly OnRetrying: Observable<ISharedAccessSignatureOnRetrying> = new Observable<ISharedAccessSignatureOnRetrying>();
}

export interface ISharedAccessSignatureOnCompleted {
    blob: SharedAccessSignatureBlob;
}

export interface ISharedAccessSignatureOnCancelled {
    abortSignal?: AbortSignal;
    error: Error;
    blob: SharedAccessSignatureBlob;
}

export interface ISharedAccessSignatureOnFailure {
    error: Error;
    blob: SharedAccessSignatureBlob;
}
export interface ISharedAccessSignatureOnProgress {
    loaded: number;
    total: number;
    blob: SharedAccessSignatureBlob;
}

export interface ISharedAccessSignatureOnRetrying {
    chunk: SharedAccessSignatureChunk;
    blob: SharedAccessSignatureBlob;
}