import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IFileDto3 } from '../../api/vincotte.via.api';
import { useAuthentication } from '../../hooks/useAuthentication';
import { FileProxyHelper } from '../../utils';

type FileDownloadMatchParams = {
    fileId: string;
    fileName: string;
}

function FileDownload() {
    const { fileId = '', fileName = '' } = useParams<FileDownloadMatchParams>();
    const { auth } = useAuthentication();

    useEffect(() => {
        if (auth) {
            const id = parseInt(fileId, 10);
            setTimeout(() => window.location.assign(FileProxyHelper.createMediaLink({
                id,
                fileName,
            } as IFileDto3)), 1);
            // setTimeout(() => window.close(), 1000);
        }
    }, []);

    return null;
}

export { FileDownload };
