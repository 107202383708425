import React, { PureComponent } from 'react';


class DashboardIcon extends PureComponent {
    render() {
        return (
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.1111 13H20.8889C20.9503 13 21 13.0497 21 13.1111V20.8889C21 20.9503 20.9503 21 20.8889 21H13.1111C13.0497 21 13 20.9503 13 20.8889V13.1111C13 13.0497 13.0497 13 13.1111 13Z" stroke="#434343" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M27.1111 13H34.8889C34.9503 13 35 13.0497 35 13.1111V20.8889C35 20.9503 34.9503 21 34.8889 21H27.1111C27.0497 21 27 20.9503 27 20.8889V13.1111C27 13.0497 27.0497 13 27.1111 13Z" stroke="#434343" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M27.1111 27H34.8889C34.9503 27 35 27.0497 35 27.1111V34.8889C35 34.9503 34.9503 35 34.8889 35H27.1111C27.0497 35 27 34.9503 27 34.8889V27.1111C27 27.0497 27.0497 27 27.1111 27Z" stroke="#434343" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M13.1111 27H20.8889C20.9503 27 21 27.0497 21 27.1111V34.8889C21 34.9503 20.9503 35 20.8889 35H13.1111C13.0497 35 13 34.9503 13 34.8889V27.1111C13 27.0497 13.0497 27 13.1111 27Z" stroke="#434343" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        );
    }
}



export { DashboardIcon };