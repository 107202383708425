import { PureComponent } from 'react';
import { Default, DefaultProps } from './Default';

type IconProps = DefaultProps;

class Icon extends PureComponent<IconProps> {
    render() {
        const { className = '', ...others } = this.props;

        const c = `btn-vincotte-icon border-0 ${className}`;

        return (
            <Default className={c} {...others} />
        );
    }
}

export { Icon };