import { Component } from 'react';

export type ErrorMessageProps = {
    message?: string | Array<string>;
}

export class ErrorMessage extends Component<ErrorMessageProps> {
    render() {
        const { message } = this.props;

        if (!message) {
            return null;
        }

        if (Array.isArray(message)) {
            return (
                <>
                    {message.length > 0 &&
                        <h2 className='bg-danger' >{message.map((m) => <>{m} <br/></>)}</h2>
                    }
                </>
            );

        }

        return (
            <>
                {message && message.trim().length > 0 && <h2 className='bg-danger' >{message}</h2>}
            </>
        );
    }
}
