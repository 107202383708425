import { createContext, ReactNode } from 'react';

interface OfflineSyncContextProps {
    processing: boolean;
    checkOfflineData: () => void;
}

const OfflineSyncContext = createContext<OfflineSyncContextProps>({
    processing: false,
    checkOfflineData: () => {},
});

OfflineSyncContext.displayName = 'OfflineSyncContext';

interface OfflineSyncProviderProps extends OfflineSyncContextProps {
    children: ReactNode;
}

function OfflineSyncProvider({children, ...state} : OfflineSyncProviderProps ) {
    return (<OfflineSyncContext.Provider value={state}>{children}</OfflineSyncContext.Provider>);
}

export { OfflineSyncContext, OfflineSyncProvider };