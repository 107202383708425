import { Component, HTMLAttributes } from 'react';
import { IClientByMonikerVm, IClientsVm } from '../../api/vincotte.via.api';
import { FileProxyHelper } from '../../utils';
import './CompanyLogo.css';

type CompanyLogoProps = HTMLAttributes<HTMLImageElement> & {
    src?: string;
    company?: IClientsVm | IClientByMonikerVm;
}

export class CompanyLogo extends Component<CompanyLogoProps> {
    public render() {
        const { src: logoSrc, company, className = '', ...rest } = this.props;

        let src = logoSrc;
        if (!src) {
            if (!company?.files || company.files.length === 0) {
                return null;
            }

            const file = company.files.at(0);
            if (!file) {
                return null;
            }

            src = FileProxyHelper.createThumbnailLink(file);

            if (!src) {
                return null;
            }
        }

        const cn = `company-logo ${className}`;

        return (
            <div className='company-logo-bloc'>
                <img src={src} {...rest} className={cn} />
            </div>
        );
    }
}
