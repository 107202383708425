import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ImgIconBase } from './ImgIconBase';

class Home extends PureComponent {
    render() {
        return (
            <ImgIconBase src='/images/icons/home.svg' {...this.props} />
        );
    }
}

Home.propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
};

export { Home };