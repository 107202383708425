import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en.json';
import ptBR from './pt-br.json';

// the translations
const resources = {
    en: { translation: en },
    'pt-BR': { translation: ptBR },
};

export const defaultLanguage = 'en';

i18n
    // .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'en',
        fallbackLng: defaultLanguage, // use English if detected language is not available
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        react: {
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p'],
            transWrapTextNodes: '',
        }
    });

// Use this for displaying options to the user on UI
export const lang = [
    { lng: 'en', name: 'English', alt: 'British English',  country: 'GB' },
    // { lng: 'pt-BR', name: 'Portuguese',  alt: 'Brazilian Portuguese', country: 'BR' },
];

export default i18n;