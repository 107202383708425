import { PureComponent } from 'react';
import { Default as DefaultButton, DefaultProps as DefaultButtonProps } from './Default';

export type CancelProps = DefaultButtonProps;

class Cancel extends PureComponent<CancelProps> {
    render() {
        const { className, ...rest } = this.props;

        return (
            <DefaultButton className={`btn-vincotte-padding cancel-btn ${className}`} {...rest} />
        );
    }
}

export { Cancel };