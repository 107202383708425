import { useAuth0 } from '@auth0/auth0-react';
import FileUploadProgress from '../components/FileUploadProgress';
import LoadingIndicator from '../components/LoadingIndicator';
import NavigationBar from '../components/NavigationBar';
import { OfflineSyncProvider } from '../components/OfflineSync';
import { PageBackgroundColorProvider } from '../components/PageBackgroundColor/PageBackgroundColorContext';
import Navigation from '../navigation';
import { useViAHooks } from './ViA.hooks';
import { useLocation } from 'react-router-dom';
import { AuthProvider } from '../auth/AuthContext';

function ViA() {
    const location = useLocation();

    const {
        isProcessingOfflineData,
        isOfflineDataChecked,
        backgroundColor,
        isMenuMinimized,
        checkOfflineData,
        setBackgroundColor,
        setMinimized,
        auth,
    } = useViAHooks();

    const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

    if (isLoading || !isOfflineDataChecked) {
        return <LoadingIndicator/>;
    }

    if (!isAuthenticated) {
        loginWithRedirect({
            appState: {
                returnTo: `${location.pathname}${location.search}`,
            },
        });
        return null;
    }

    if (!auth) {
        return <LoadingIndicator/>;
    }

    const rowStyle = { margin: '0' };

    return (
        <AuthProvider auth={auth} >
            <OfflineSyncProvider processing={isProcessingOfflineData} checkOfflineData={checkOfflineData} >
                <FileUploadProgress>
                    <div className='fill-height' style={{ backgroundColor }}>
                        <PageBackgroundColorProvider color={ backgroundColor } setBackgroundColor={setBackgroundColor} >
                            <div className='row fill-height' style={rowStyle}>
                                <NavigationBar isMenuMinimized={isMenuMinimized} setMinimized={setMinimized} />

                                <Navigation isMenuMinimized={isMenuMinimized} />
                            </div>
                        </PageBackgroundColorProvider>
                    </div>
                </FileUploadProgress>
            </OfflineSyncProvider>
        </AuthProvider>
    );
}

export { ViA };
