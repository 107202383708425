import { PureComponent } from 'react';
import { ImgIconBase, ImgIconDetailedHTMLProps } from './ImgIconBase';

type WarningProps = ImgIconDetailedHTMLProps;

export class Warning extends PureComponent<WarningProps> {

    render() {
        const { className = '', ...rest } = this.props;
        const c = `warning-icon ${className}`;

        return (
            <ImgIconBase className={c} src='/images/icons/warning.svg' {...rest} />
        );
    }
}
