import { StreamingUrlDTO } from '../api/vincotte.via.api';
import { Entity } from './Entity';

export class File extends Entity {

    constructor(metadata = null) {
        super(metadata);
        if (this.streamingUrls === undefined) {
            this.streamingUrls = [];
        }
    }

    static get JSON_METADATA() {
        return ['id', 'name', 'fileName', 'path', 'mediaLink', 'type', 'isExternal', 'prefix', 'thumbnail', 'streamingUrls'];
    }

    get id() {
        return this._id;
    }

    set id(id) {
        this._id = id;
    }

    get fileId() {
        return this.id;
    }

    get name() {
        return this._name;
    }

    set name(name) {
        this._name = name;
    }

    get fileName() {
        return this._fileName;
    }

    set fileName(fileName) {
        this._fileName = fileName;
    }

    get path() {
        return this._path;
    }

    set path(path) {
        this._path = path;
    }

    get mediaLink() {
        return this._mediaLink;
    }

    set mediaLink(mediaLink) {
        this._mediaLink = mediaLink;
    }

    get type() {
        return this._type;
    }

    set type(type) {
        this._type = type;
    }

    get isExternal() {
        return this._isExternal;
    }

    set isExternal(isExternal) {
        this._isExternal = isExternal;
    }

    get prefix() {
        return this._prefix;
    }

    set prefix(prefix) {
        this._prefix = prefix;
    }

    get thumbnail() {
        return this._thumbnail;
    }

    set thumbnail(thumbnail) {
        this._thumbnail = thumbnail;
    }

    get streamingUrls() {
        return this._streamingUrls;
    }

    set streamingUrls(streamingUrls) {
        this._streamingUrls = streamingUrls;
    }

    merge(object) {
        if (object.streamingUrls && Array.isArray(object.streamingUrls)) {
            object = {
                ...object,
                streamingUrls: object.streamingUrls.map((streamingUrl) =>(
                    streamingUrl instanceof StreamingUrlDTO ? streamingUrl : StreamingUrlDTO.fromJS(streamingUrl)
                ))
            };
        }

        super.merge(object);
    }
}