import { PureComponent } from 'react';
import { ImgIconBase, ImgIconDetailedHTMLProps } from './ImgIconBase';

type ResolutionProps = ImgIconDetailedHTMLProps;

export class Resolution extends PureComponent<ResolutionProps> {
    render() {
        return (
            <ImgIconBase src='/images/icons/video-player/resolution.svg' {...this.props} />
        );
    }
}