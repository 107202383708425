import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Text as TextButton } from '../Buttons/Text';

interface FailedProgressProps {
    children: ReactNode;
    onClose: VoidFunction;
}

function FailedProgress({ children, onClose }: FailedProgressProps) {
    const { t } = useTranslation();

    return (
        <div className='m-1 p-3 bg-white rounded vincotte-small-shadow'>
            <div className='row no-gutters' >
                <div className='col' >
                    <h2 style={{ fontSize: '1rem !important' }} >{t('components.FileUploadProgress.File upload failed')}</h2>
                    <h3 style={{ fontSize: '0.8rem !important' }} >{t('components.FileUploadProgress.The following files could not be uploaded')}:</h3>

                    {children}

                    <h3 style={{ fontSize: '1rem !important' }} >{t('components.FileUploadProgress.Causes might be')}:</h3>
                    <p>{t('components.FileUploadProgress.Internet connection')}</p>

                    <p className='mt-3'>{t('components.FileUploadProgress.You can also try to rename and upload again')}</p>
                </div>

                <div className='col text-right' style={{ flex: '0 0 80px' }} >
                    <TextButton
                        onClick={onClose}
                        title={t('components.FileUploadProgress.Close')}
                        style={{
                            fontSize: '1.5rem !important',
                            fontWeight: 'normal !important',
                        }}
                    >X</TextButton>
                </div>
            </div>
        </div>
    );
}

export { FailedProgress };
