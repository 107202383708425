import { ButtonHTMLAttributes, MouseEventHandler, PureComponent } from 'react';
import './MainMenu.css';

export type DefaultProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    onClick: MouseEventHandler<HTMLButtonElement>;
    isActive?: boolean;
}

class MainMenu extends PureComponent<DefaultProps> {
    render() {
        const { className, isActive = false, ...others } = this.props;

        let c = `nav-dropdown ${className}`;
        if (isActive) {
            c += ' active';
        }

        return (<button type={'button'} className={c} {...others} />);
    }
}

export { MainMenu };