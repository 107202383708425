import { DateOnly, IDateOnly } from '../api/vincotte.via.api';

export class DateOnlyHelper {

    static toString(date: IDateOnly) : string {
        if (!date) {
            return '';
        }

        if (!(date instanceof DateOnly)) {
            return (new DateOnly(date)).toString();
        }

        return date.toString();
    }

    static toPrintDate(date?: IDateOnly | string) : string {
        if (!date) {
            return '';
        }

        if (date instanceof DateOnly) {
            const { year = 0, month = 0, day } = date;

            return (new Date(year, month, day)).toLocaleDateString();
        }

        return date as string;
    }

    static inputField(date: IDateOnly | string) : string {
        if (!date || date === '') {
            return '';
        }

        if (date instanceof DateOnly) {
            return DateOnlyHelper.toString(date);
        }

        return date as string;
    }
}