import { CSSProperties, MouseEvent, PureComponent } from 'react';
import { WithTranslation } from 'react-i18next';
import { ProductionLineDto, ProductionLinesVm, ProductionLineVm } from '../../api/vincotte.via.api';
import ProductionUnitDeleteCard from '../ProductionUnitDeleteCard';
import ProtectedElement from '../ProtectedElement';
import ThreeDotsMenu, { Entry, Separator } from '../ThreeDotsMenu';
import { ThreeDotsMenuPosition } from '../ThreeDotsMenu/ThreeDotsMenu';


interface ProductionUnitMenuProps extends Pick<WithTranslation, 't'> {
    productionUnit: ProductionLinesVm | ProductionLineDto | ProductionLineVm;
    position?: ThreeDotsMenuPosition;
    style?: CSSProperties;
    onDeleteSuccess?: () => void;
}

interface ProductionUnitMenuState {
    showDeleteMenu: boolean;
}

export class ProductionUnitMenu extends PureComponent<ProductionUnitMenuProps,ProductionUnitMenuState> {
    private readonly threeDotsMenuStyle : CSSProperties = {
        top: '1em',
        right: '1em',
    };

    public readonly state: Readonly<ProductionUnitMenuState> = {
        showDeleteMenu: false,
    };

    showDeleteMenu = (event: MouseEvent<HTMLAnchorElement>) => {
        if (event.cancelable) {
            event.stopPropagation();
            event.preventDefault();
        }

        this.setState({ showDeleteMenu: true });
    }

    hideDeleteMenu = () => this.setState({ showDeleteMenu: false });

    onDeleteProductionUnit = () => {
        this.hideDeleteMenu();

        if (this.props.onDeleteSuccess) {
            this.props.onDeleteSuccess();
        }
    }

    render() {
        const { t, position, productionUnit, style = this.threeDotsMenuStyle  } = this.props;
        const { showDeleteMenu } = this.state;

        return(
            <>
                <ProtectedElement somePermission={['CanEditProductionLine']} >
                    <ThreeDotsMenu background="transparent" style={style} position={position} >
                        <ProtectedElement permissions={['CanEditProductionLine']} >
                            <Entry style={{color:'black'}} className='optionItem' title="delete" to={`/production-unit/edit/${productionUnit.id}`}>
                                {t('components.ProductionUnits.edit')}
                            </Entry>
                        </ProtectedElement>

                        <Separator />

                        <ProtectedElement permissions={['CanDeleteProductionLine']} >
                            <Entry title="delete" onClick={this.showDeleteMenu} >
                                {t('components.Assets.delete')}
                            </Entry>
                        </ProtectedElement>
                    </ThreeDotsMenu>
                </ProtectedElement>

                {
                    showDeleteMenu &&
                    <ProductionUnitDeleteCard
                        productionUnits={[productionUnit]}
                        onDelete={this.onDeleteProductionUnit}
                        onClose={this.hideDeleteMenu} />
                }
            </>
        );
    }
}