import React, { ForwardRefExoticComponent, ForwardedRef, ReactNode, forwardRef } from 'react';

interface ProgressListProps {
    className?: string;
    children?: ReactNode;
}

//  : FunctionComponent<ProgressListProps>
function ProgressList({ className, children, ...rest }: ProgressListProps, ref: ForwardedRef<HTMLUListElement>) {
    return (
        <ul
            className={`list-group p-1 ${className}`}
            ref={ref}
        >
            {children}
        </ul>
    );
}

const ProgressListWithRef: ForwardRefExoticComponent<ProgressListProps & React.RefAttributes<HTMLUListElement>> = forwardRef(ProgressList);

export { ProgressListWithRef as ProgressList };
