import { WithTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ClientsVm } from '../../api/vincotte.via.api';
import Card from '../../components/Card';
import { CompanyMenu } from '../../components/Companies/CompanyMenu';
import CompanyLogo from '../../components/CompanyLogo';
import { AutoCol, AutoRow } from '../../components/Layout';

interface CompanyProps extends Pick<WithTranslation, 't'> {
    client: ClientsVm;
    onDeleteSuccess?: () => void;
}

function Company(props: CompanyProps) {
    const { t, client, onDeleteSuccess } = props;
    const {
        name,
        moniker,
    } = client;

    const assetLink = `/company/${moniker}`;

    return (
        <Card backgroundColor='company' className='company-card' >
            <CompanyMenu t={t} company={client} onDeleteSuccess={onDeleteSuccess} />

            <nav>
                <AutoRow>
                    <AutoCol>
                        <CompanyLogo company={client} />
                    </AutoCol>

                    <AutoCol>
                        <h3 className='mb-0'>
                            <Link to={assetLink} >{name}</Link>
                        </h3>
                    </AutoCol>
                </AutoRow>
            </nav>
        </Card>
    );
}

export { Company };
