import { ID } from '../utils/ID';

export class Entity {
    constructor(metadata = null) {
        this._memoryId = metadata && metadata._memoryId || ID();

        if (metadata !== null) {
            this.merge(metadata);
        }
    }

    get memoryId() {
        return this._memoryId;
    }

    static get JSON_METADATA() {
        throw new Error('JSON_METADATA isn\'t implemented. You must extend Entity class and implement JSON_METADATA');
    }

    isSame(entity) {
        if (!(entity instanceof Entity)) {
            throw new Error('The entity parameter must be an instance of Entity');
        }

        return this.memoryId === entity.memoryId;
    }

    toJSON() {
        return this.toJson();
    }

    toJson() {
        const ret = {};

        const convertToJson = (element) => {
            if (Array.isArray(element)) {
                element = element.map(convertToJson);

                return element;
            }

            if (typeof element !== 'object') {
                return element;
            }

            if (element instanceof Entity) {
                return element.toJson();
            }

            if ('toJSON' in element) {
                return element.toJSON();
            }

            return element;
        };

        this.constructor.JSON_METADATA.filter(
            (k) => this[k] === false || this[k] === 0  || this[k]
        ).forEach((k) => ret[k] = convertToJson(this[k]));

        return ret;
    }

    merge(object) {
        this.constructor.JSON_METADATA.filter(
            (k) => object[k] === false || object[k] === 0 || object[k]
        ).forEach((k) => this[k] = object[k]);
    }

    clone() {
        const cloned = new this.constructor(this.toJson());
        cloned._memoryId = this.memoryId;
        return cloned;
    }

    static fromJson(metadata) {
        return new this(metadata);
    }

    static stringify(entity) {
        return entity instanceof this || 'toJson' in entity
            ? JSON.stringify(entity.toJson())
            : JSON.stringify(entity);
    }
}
