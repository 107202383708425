import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withConnection, WithConnectionProps } from '../../HOC/withConnection';
import * as Icons from '../Icons';
import { OfflineSyncContext } from '../OfflineSync';
import './InternetStatus.css';

interface InternetStatusProps extends WithConnectionProps {
    isMenuMinimized?: boolean;
}

function InternetStatus(props: InternetStatusProps) {
    const { t } = useTranslation();
    const { isOnline, isMenuMinimized } = props;

    const style = {} as Record<string, any>;

    const { processing, checkOfflineData } = useContext(OfflineSyncContext);

    useEffect(() => {
        if (isOnline && !processing) {
            checkOfflineData();

            console.log('offline database check');
        }
    }, [isOnline]);

    style.background = isOnline
        ? 'linear-gradient(-23deg, rgba(5,45,118,1) 0%, rgba(0,91,195,1) 100%)'
        : 'linear-gradient(-23deg, rgba(56,0,0,1) 0%, rgba(97,76,0,1) 100%)';

    const status = isOnline ? t('components.InternetStatus.Online') : t('components.InternetStatus.Offline');

    const visibilityClass = 'mb-1 pr-1';

    return (
        <div className="nav-item-border-bottom nav-item-height" style={{padding: '16px'}}>
            <div id='internet-notification-container' style={style} >
                <Icons.Online style={{marginRight: '-.2em'}} className={!isOnline ? 'd-none' : visibilityClass } />
                <Icons.Offline style={{marginRight: '-.2em'}}  className={isOnline ? 'd-none' : visibilityClass } />
                {!isMenuMinimized && status}
            </div>
        </div>

    );
}

InternetStatus.displayName = 'InternetStatus';

const InternetStatusWithConnection = withConnection()(InternetStatus);
export { InternetStatusWithConnection as InternetStatus };
