import { StringHelper } from '../../utils';

export class Medialink {
    constructor(medialink: string | URL) {
        this.medialink = medialink;
        this.name = StringHelper.getFileName(medialink, true);
        this.fileName = StringHelper.getFileName(medialink);
    }

    public readonly medialink: string | URL;

    public readonly name: string;

    public readonly fileName: string;

    static fromMediaLink(medialink: string | URL) {
        return new Medialink(medialink);
    }
}