import { ID } from './ID';

const shouldPreventUnload = () => {
    window.onbeforeunload = PreventWindowUnload.shouldPrevent() ? () => true : null;
};

export class PreventWindowUnload {

    static pool: Record<string, any> = {};

    static prevent(id: string, component: any) {
        PreventWindowUnload.pool[id] = component;
        shouldPreventUnload();
    }

    static release(id: string) {
        const component = PreventWindowUnload.pool[id];
        delete PreventWindowUnload.pool[id];
        shouldPreventUnload();
        return component;
    }

    static shouldPrevent() {
        return Object.keys(PreventWindowUnload.pool).length > 0;
    }

    static generateId(component?: any) : string {
        const id = `${ID()}`;

        const generatedId = component && ('constructor' in component) ? `${id}-${component.constructor.name}` : id;

        if (generatedId in PreventWindowUnload.pool) {
            return PreventWindowUnload.generateId(component);
        }

        return generatedId;
    }
}