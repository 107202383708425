import { forwardRef, InputHTMLAttributes, ReactNode, Ref, useRef } from 'react';
import { ID } from '../../utils/ID';

type CustomRangeProps = InputHTMLAttributes<HTMLInputElement> & {
    hideSliderValue?: boolean;
    label?: string;
    prefix?: string;
    sufix?: string;
    children?: ReactNode,
}

const CustomRange = forwardRef((props: Readonly<CustomRangeProps>, ref: Ref<HTMLInputElement>) => {
    const {
        label,
        value,
        className,
        prefix,
        sufix,
        hideSliderValue = false,
        ...rest
    } = props;

    const id = useRef(ID());

    const renderedClass = `custom-range ${className}`;

    const hasLabel: boolean = (label !== undefined && label.length > 0);
    const hasPreffix: boolean = (prefix !== undefined && prefix.length > 0);
    const sliderClass = hasPreffix ? 'w-50' : 'w-75';

    return (
        <>
            {hasLabel && <label htmlFor={id.current}>{label}:</label>}

            <div className="d-flex justify-content-center my-4">
                {
                    hasPreffix &&
                    <span className="font-weight-bold text-primary mr-2" style={{ width: '2rem', textAlign: 'right' }}>
                        {prefix}
                    </span>
                }

                <div className={sliderClass}>
                    <input
                        type="range"
                        value={value}
                        className={renderedClass}
                        id={id.current}
                        ref={ref}
                        {...rest} />
                </div>

                <span className="font-weight-bold text-primary ml-2" style={{ width: '2rem', textAlign: 'right' }}>
                    {!hideSliderValue && value}{sufix}
                </span>
            </div>
        </>
    );
});

CustomRange.displayName = 'CustomRange';

export { CustomRange };
