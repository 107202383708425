
import { AxiosDefaults, AxiosInstance } from 'axios';
import { useMemo } from 'react';
import { useAxios } from './useAxios';

export interface IService<Type> {
    new(baseUrl?: string, instance?: AxiosInstance): Type;
}

export interface UseAxiosService<T> {
    service: T
}

export function useAxiosService<TService>(service: IService<TService>, axiosDefaults?: AxiosDefaults) : UseAxiosService<TService> {
    const { axios, baseURL } = useAxios();

    if (axiosDefaults && axios) {
        axios.defaults = { ...axiosDefaults, ...axios.defaults };
    }

    const instance = useMemo(() => new service(baseURL, axios), [ service ]);

    return {
        service: instance,
    } as UseAxiosService<TService>;
}