function stringfiableObject(object: Record<string, any>) : Record<string, any> {
    const filtered = {} as Record<string, any>;
    for (const key in object) {
        if (['boolean', 'number', 'string'].includes(typeof object[key]) || object[key] === null) {
            filtered[key] = object[key];
        }
    }

    return filtered;
}

// static entriesFromObject = (object) => {
//     const o = JsonHelper.stringfiableObject(object);
//     const ret = [];

//     for (const [key, value] of Object.entries(o)) {
//         ret[key] = value;
//     }

//     return ret;
// }

type Primitive =
    | null
    | undefined
    | string
    | number
    | boolean
    | symbol
    | bigint;
    type NullToUndefined<T> = T extends null
    ? undefined
    // eslint-disable-next-line @typescript-eslint/ban-types
    : T extends Primitive | Function | Date | RegExp
    ? T
    : T extends Array<infer U>
    ? Array<NullToUndefined<U>>
    : T extends Map<infer K, infer V>
    ? Map<K, NullToUndefined<V>>
    : T extends Set<infer U>
    ? Set<NullToUndefined<U>>
    : T extends object
    ? { [K in keyof T]: NullToUndefined<T[K]> }
    : unknown;

export type UndefinedToNull<T> = T extends undefined
    ? null
    // eslint-disable-next-line @typescript-eslint/ban-types
    : T extends Primitive | Function | Date | RegExp
    ? T
    : T extends Array<infer U>
    ? Array<UndefinedToNull<U>>
    : T extends Map<infer K, infer V>
    ? Map<K, UndefinedToNull<V>>
    : T extends Set<infer U>
    ? Set<NullToUndefined<U>>
    : T extends object
    ? { [K in keyof T]: UndefinedToNull<T[K]> }
    : unknown;

function _nullToUndefined<T>(obj: T): NullToUndefined<T> {
    if (obj === null) {
        return undefined as any;
    }

    if (typeof obj === 'object') {
        if (obj instanceof Map) {
            obj.forEach((value, key) => obj.set(key, _nullToUndefined(value)));
        } else {
            for (const key in obj) {
                obj[key] = _nullToUndefined(obj[key]) as any;
            }
        }
    }

    return obj as any;
}

/**
   * Recursively converts all null values to undefined.
   *
   * @param obj object to convert
   * @returns a copy of the object with all its null values converted to undefined
   */
export function nullToUndefined<T>(obj: T) {
    return _nullToUndefined(structuredClone(obj));
}

const JsonHelper = {
    stringfiableObject,
    nullToUndefined
} as const;

export { JsonHelper };


