import { Component } from 'react';
import { ImgIconBase } from './ImgIconBase';

class Close2 extends Component {
    render() {
        return (
            <ImgIconBase src='/images/icons/close-icon.svg' {...this.props} />
        );
    }
}

export { Close2 };